import styles from './icons.module.css';

export const DocIcon = () => (
    <div className={styles.docIcon}>
        <svg
            width='26'
            height='26'
            viewBox='0 0 26 26'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.47746 1.46338H3.62158V2.31925V24.1441V24.9999H4.47746H22.4508H23.3067V24.1441V8.73831V8.3838L23.056 8.13312L16.637 1.71406L16.3863 1.46338H16.0318H4.47746ZM5.33333 23.2882V3.17513H14.7481V8.73888V9.59475H15.604H21.5949V23.2882H5.33333ZM20.3851 7.883L16.4599 3.95777V7.883H20.3851ZM7.90096 21.1485H19.8832V19.4367H7.90096V21.1485ZM7.90064 16.8695H19.8829V15.1577H7.90064V16.8695ZM13.0359 12.5904H7.90064V10.8786H13.0359V12.5904Z'
                fill='#3E3E59'
            />
        </svg>
    </div>
);
