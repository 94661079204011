import { Suspense } from 'react';
import { Await, useLoaderData } from 'react-router-dom';

import { Enter } from '../enter';

import styles from './lazy-enter-page.module.css';

import { AnimatedEllipsis } from '~/shared/animated-ellipsis';
import { CustomSpinner } from '~/shared/custom-spinner';
import { EnterStepsLoaderData } from '~/shared/types';

const CRYPTO_PLUGIN_CHECKED_TEXT = 'Пожалуйста, подождите, проверяется наличие продуктов КриптоПро';

export const LazyEnterPage = () => {
    const data = useLoaderData() as EnterStepsLoaderData;

    return (
        <Suspense
            fallback={
                <CustomSpinner
                    tip={<AnimatedEllipsis title={CRYPTO_PLUGIN_CHECKED_TEXT} />}
                    className={styles.loader}
                    size='large'
                />
            }
        >
            <Await resolve={data.isPluginInstalled}>
                {(props: boolean) => <Enter isPluginInstalled={props} />}
            </Await>
        </Suspense>
    );
};
