import { UploadFile } from 'antd/es/upload';

import { FileExtension } from '~/rtk-queries/types/certificate-verification';
import { convertToBase64 } from '~/utils/convert-to-base64';

export const FILE_SIGN_EXTENSION = [
    FileExtension.sig,
    FileExtension.sgn,
    FileExtension.sign,
    FileExtension.p7s,
    FileExtension.enc,
];

export const returnBase64Array = async (content: UploadFile[]) => {
    const splitFirstElement = content[0].name.split('.');
    const ending = splitFirstElement.at(-1) as (typeof FILE_SIGN_EXTENSION)[number];

    const newContent = FILE_SIGN_EXTENSION.includes(ending) ? content.reverse() : content;

    const base64Array = await Promise.all(
        newContent.map(async ({ originFileObj }) =>
            originFileObj ? convertToBase64(originFileObj) : null,
        ),
    );

    const originalDocument = FILE_SIGN_EXTENSION.includes(ending)
        ? content[0].name
        : splitFirstElement.join('.');

    return { base64Array, originalDocument };
};
