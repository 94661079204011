import { useRef, useState } from 'react';
import { Button, Typography } from 'antd';
import classNames from 'classnames';

import { ReviewModal } from '../review-modal';

import { ContactUsOpenIcon, ReviewIcon } from './custom-icon';

import { BUTTON_TEXT } from '~/constants/button';
import { CONTACT_US, CONTACT_US_MODAL } from '~/constants/contact-us';
import { useClickOutside } from '~/hooks/use-click-outside';
import { CustomButton } from '~/shared/custom-button';
import { CustomModal } from '~/shared/custom-modal';
import { useGetFrontConfigQuery } from '~/rtk-queries/endpoints/front-config';
import { convertFrontConfigLinkToContactUs } from '~/utils/convert-front-config-link-to-contact-us';

import styles from './contact-us.module.css';

export const ContactUs = () => {
    const [isOpenContactUs, setIsOpenContactUs] = useState(false);
    const [isOpenModalReview, setIsOpenModalReview] = useState(false);
    const [value, setValue] = useState('');
    const contactUsRef = useRef<HTMLInputElement | null>(null);

    const { data: frontConfig } = useGetFrontConfigQuery();

    useClickOutside(contactUsRef, () => {
        if (!isOpenModalReview) {
            setIsOpenContactUs(false);
        }
    });

    const onClickStateContactUs = () => {
        setIsOpenContactUs(!isOpenContactUs);
    };

    const onClickReview = () => {
        setIsOpenModalReview(true);
    };

    const onClickSendReview = () => {
        setIsOpenModalReview(!isOpenModalReview);
        setValue('');
    };

    return (
        <nav ref={contactUsRef} className={styles.contactUsBlock}>
            <Button
                className={styles.contactUsButton}
                shape='circle'
                onClick={onClickStateContactUs}
                icon={<ContactUsOpenIcon className={styles.contactUsButtonIcon} />}
            />
            <ul
                className={classNames(
                    styles.contactUsMenu,
                    isOpenContactUs && styles.contactUsMenuOpen,
                )}
            >
                {convertFrontConfigLinkToContactUs({ frontConfig, dataContactUs: CONTACT_US }).map(
                    ({ title, link, icon }) => (
                        <li key={title} className={styles.contactUsItem}>
                            <a
                                className={styles.contactUsLink}
                                target='_blank'
                                href={link}
                                rel='noopener noreferrer nofollow'
                            >
                                <span className={styles.contactUsLinkTitle}>
                                    <Typography.Text className={styles.title}>
                                        {title}
                                    </Typography.Text>
                                    <img
                                        src={icon}
                                        className={styles.contactUsLinkLogo}
                                        alt='logo'
                                    />
                                </span>
                            </a>
                        </li>
                    ),
                )}
                <li key={CONTACT_US_MODAL.title}>
                    <Button
                        icon={<ReviewIcon />}
                        iconPosition='end'
                        onClick={onClickReview}
                        className={styles.contactUsReviewsButton}
                    >
                        {CONTACT_US_MODAL.title}
                    </Button>
                </li>
            </ul>
            <CustomModal
                setOpenModal={setIsOpenModalReview}
                open={isOpenModalReview}
                isClose={true}
                footer={[
                    <CustomButton
                        key={BUTTON_TEXT.send}
                        className='enterPageButtonYes'
                        onClick={onClickSendReview}
                    >
                        {BUTTON_TEXT.send}
                    </CustomButton>,
                ]}
            >
                <ReviewModal value={value} setValue={setValue} />
            </CustomModal>
        </nav>
    );
};
