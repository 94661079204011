// TODO getSvsPolicy
export type GetSvsPolicy = {
    vsPolicy: {
        signatureDescriptions: Array<SignatureDescription>;
        certificateVerifiers: Array<CertificateVerifier>;
        chainInclusionHint: boolean;
    };
};
export enum FileExtension {
    pdf = 'pdf',
    docx = 'docx',
    xml = 'xml',
    bin = 'bin',
    sig = 'sig',
    p7s = 'p7s',
    sign = 'sign',
    sgn = 'sgn',
    enc = 'enc',
    anyone = '*',
}

export type SignatureDescription = {
    signatureType: string;
    fileExtensions: Array<FileExtension[number]>;
};

export type CertificateVerifier = {
    id: number;
    className: string;
    assemblyName: string;
    pluginDescription: string;
    checkByDefaultRequired: boolean;
    parameters: {
        TSLPath: string;
    };
};

// TODO checkSignatureDocument
export type CheckSignatureDocumentRequest = {
    content: string | null;
    source: string | null;
    certificate: string | null;
    verifyParams: {
        isDetached: boolean;
        hash?: boolean;
        hashAlgorithm?: string;
    };
    signatureType?: string;
    certVerifiersPluginsIds?: number[];
};

export type CheckSignatureDocumentResponse = {
    verificationResult: {
        verificationResults: Array<VerificationResult>;
        messageDetail: string | null;
        error: string | null;
        errorDescription: string | null;
    };
};

// TODO checkVerifyCertificate
export type CheckVerifyCertificateRequest = {
    content: string | null;
    certVerifiersPluginsIds?: number[];
};

export type CheckVerifyCertificateResponse = {
    verificationResult: VerificationResult;
};

export type SignerCertificateInfo = {
    SubjectName: string;
    IssuerName: string;
    NotBefore: string;
    NotBeforeUTC: string;
    NotAfter: string;
    NotAfterUTC: string;
    SerialNumber: string;
    Thumbprint: string;
    PkNotBeforeUTC: string;
    PkNotAfterUTC: string;
    CertificateRevocationDateUtc: string;
    RevocationReasonDisplayName: string;
    KeyIdentifier: string;
};

export type VerificationResult = {
    message: string;
    result: false;
    signerCertificate: string;
    signerCertificateInfo: SignerCertificateInfo;
    signatureInfo: SignatureInfo | null;
    additionalCertificateResult: AdditionalCertificateResult | null;
    additionalCertificatesInfo: null;
    details: null;
};

export type AdditionalCertificateResult = Array<{
    assemblyName: string;
    pluginDescription: string;
    bResult: boolean;
    errorsList: Array<string>;
}>;

export type SignatureInfo = {
    CAdESType: string;
    LocalSigningTime: string;
    LocalSigningTimeUTC: string;
};
