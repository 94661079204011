import { API } from '../constants/api';
import { transformErrorWithAlert } from '../helpers/transform-error-with-alert';
import { interceptor } from '../interceptor';

import { AlertMessage } from '~/constants/alert-message';
import { ApiGroupNames } from '~/rtk-queries/constants/api-group-names';
import { EndpointNames } from '~/rtk-queries/constants/endpoint-names';
import {
    CertRequestPKIResponse,
    PKICertificateResponse,
    PKIRevokeRequest,
    PKIRevokeResponse,
    PKIUserResponse,
} from '~/rtk-queries/types/pki';

export const pkiInfo = interceptor.injectEndpoints({
    endpoints: (builder) => ({
        getPKIUser: builder.query<PKIUserResponse, string>({
            query: (userId) => ({
                url: `${API.pkiApi}/user/${userId}`,
                method: 'GET',
                apiGroupName: ApiGroupNames.PKI_INFO,
                name: EndpointNames.GET_PKI_USER,
            }),
            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),

        getPKICertificate: builder.query<PKICertificateResponse, string>({
            query: (certificateId) => ({
                url: `${API.pkiApi}/certificate/${certificateId}`,
                method: 'GET',
                apiGroupName: ApiGroupNames.PKI_INFO,
                name: EndpointNames.GET_PKI_CERTIFICATE,
            }),
            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),
        getPKICertRequest: builder.query<CertRequestPKIResponse, string>({
            query: (certRequestId) => ({
                url: `${API.pkiApi}/cert-request/${certRequestId}`,
                method: 'GET',
                apiGroupName: ApiGroupNames.PKI_INFO,
                name: EndpointNames.GET_PKI_CERT_REQUEST,
            }),
            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),
        getPKIRevoke: builder.query<PKIRevokeRequest, string>({
            query: (certificateId) => ({
                url: `${API.pkiApi}/revoke-request/${certificateId}`,
                method: 'GET',
                apiGroupName: ApiGroupNames.PKI_INFO,
                name: EndpointNames.GET_PKI_REVOKE,
            }),
            transformResponse: (response: PKIRevokeResponse) => response.pkiRevokeRequest,
            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),
    }),
});

export const {
    useLazyGetPKICertRequestQuery,
    useLazyGetPKICertificateQuery,
    useLazyGetPKIUserQuery,
    useLazyGetPKIRevokeQuery,
} = pkiInfo;
