import {
    INPUTS_IF_DISPLAYNAME_NULL,
    PRIVATE_ROOM,
    PrivateRoomInputsKeyName,
} from '../constants/private-room';
import { UserData, UserInfoData, UserInfoDataValue } from '../types/user-info';

import { rulesInput } from './rules-input';

export const convertUserDataToArr = (data?: UserInfoData) => {
    const arr = [] as UserData[];

    if (data) {
        Object.entries(data).forEach(
            ([key, value]: [
                key: PrivateRoomInputsKeyName | string,
                value: UserInfoDataValue | string | null,
            ]) => {
                if (value && typeof value !== 'string' && typeof value !== 'number') {
                    arr.push({
                        name: [key],
                        label: value.displayName,
                        isDisabled: !value.enabled ?? false,
                        mask:
                            PRIVATE_ROOM.inputs.addressOtherBlock.find(
                                ({ keyName }) => keyName === key,
                            )?.mask ?? '',
                        rules: rulesInput({
                            required: value.required,
                            regex: value.regex,
                            maxLength: value.maxLength,
                            minLength: value.minLength,
                        }),
                    });
                }
                if (key && value === null && key !== 'gender') {
                    arr.push({
                        name: [key],
                        label:
                            INPUTS_IF_DISPLAYNAME_NULL.find(({ keyName }) => keyName === key)
                                ?.label ?? '',
                        mask:
                            INPUTS_IF_DISPLAYNAME_NULL.find(({ keyName }) => keyName === key)
                                ?.mask ?? '',
                        isDisabled: false,
                    });
                }
            },
        );
    }

    return arr;
};
