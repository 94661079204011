import { useGetSvsPolicyQuery } from '~/rtk-queries/endpoints/certificate-verification';
import {
    CertificateVerifier,
    GetSvsPolicy,
    SignatureDescription,
} from '~/rtk-queries/types/certificate-verification';
import { CommonResponse } from '~/types/common';
import { isArrayWithItems } from '~/utils/is-array-with-items';

type UseGetSvsPolicyReturn = {
    isCertificateVerifierExist: boolean;
    isLoadingGetSvsPolicy: boolean;
    certificateVerifiers: CertificateVerifier[];
    signatureDescriptions: SignatureDescription[];
};

export const useGetSvsPolicy = (): UseGetSvsPolicyReturn => {
    const { data = {} as CommonResponse<GetSvsPolicy>, isLoading: isLoadingGetSvsPolicy } =
        useGetSvsPolicyQuery();

    const vsPolicy = data?.vsPolicy ?? {
        certificateVerifiers: [] as Array<CertificateVerifier>,
        signatureDescriptions: [] as Array<SignatureDescription>,
        chainInclusionHint: false,
    };

    const { certificateVerifiers, signatureDescriptions } = vsPolicy;

    const isCertificateVerifierExist = isArrayWithItems(certificateVerifiers);

    return {
        isLoadingGetSvsPolicy,
        isCertificateVerifierExist,
        certificateVerifiers,
        signatureDescriptions,
    };
};
