import React, { ReactElement } from 'react';
import { CloseCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { Divider, Space, Typography } from 'antd';
import classNames from 'classnames';

import styles from './view-answer-modal.module.css';

import { VIEW_ANSWER } from '~/components/view-answer-modal/constants/view-answer';
import { DetailsSection } from '~/components/view-answer-modal/details-section/details-section';
import { ModalTextVariant } from '~/constants/modal';
import { DetailsType } from '~/utils/transform-certificates-data/type';

export type ViewAnswerModalProps = {
    isError: boolean;
    text?: string | string[] | null;
    title?: string | ReactElement;
    details?: DetailsType[];
};

export const ViewAnswerModal = ({ isError, text, title, details }: ViewAnswerModalProps) => {
    const titleText = isError && !title ? VIEW_ANSWER.viewAnswerModal.title : title;

    const enricherData = details?.map(({ enricher, status, errorMessage }) => ({
        enricher,
        status,
        errorMessage,
    }));

    const isStartAlign = React.isValidElement(title) || isError;

    return (
        <Space
            className={styles.space}
            direction='vertical'
            align={details || isError ? 'start' : 'center'}
        >
            <Typography.Title
                level={3}
                className={classNames(styles.center, {
                    [styles.start]: title === ModalTextVariant.answer || isStartAlign,
                })}
            >
                {isError && <CloseCircleFilled className={styles.errorIcon} />} {titleText}
            </Typography.Title>
            {text && (
                <Typography.Text
                    className={classNames(styles.center, {
                        [styles.start]:
                            (isStartAlign && text !== ModalTextVariant.keyUndefined) ||
                            text === ModalTextVariant.generateKeys,
                        [styles.leftPadding]: isError,
                    })}
                >
                    {(text === ModalTextVariant.installOk ||
                        text === ModalTextVariant.removeDraft) && (
                        <ExclamationCircleFilled className={styles.successIcon} />
                    )}
                    {text}
                </Typography.Text>
            )}
            {details && (
                <React.Fragment>
                    <Divider className={styles.divider} />
                    {enricherData && <DetailsSection enricherData={enricherData} />}
                    <Divider className={styles.divider} />
                </React.Fragment>
            )}
        </Space>
    );
};
