import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

import { App } from './components/app';
import { CustomAlert } from './components/custom-alert';
import { store } from './store';

import './index.css';

dayjs.extend(utc);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Provider store={store}>
        <App />
        <CustomAlert />
    </Provider>,
);
