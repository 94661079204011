import dayjs, { Dayjs } from 'dayjs';

import { DATE_FORMAT } from '~/constants/general';

export type ConvertDateToStringType = {
    date?: Dayjs | null;
    dateFormat?: string;
};

export const convertDateToString = ({
    date,
    dateFormat = DATE_FORMAT,
}: ConvertDateToStringType): string => (date ? dayjs(date).format(dateFormat) : '');
