export const FORMS_DATA = {
    checkCertificateForm: {
        title: 'Сервис проверки статуса сертификата ключа проверки электронной подписи',
    },
    checkDocumentForm: {
        title: 'Сервис проверки цифровой подписи подписанного документа',
        uploadTitle: 'Документ или файл с подписью',
        uploadOneFile: 'Нажмите чтобы загрузить подписанный документ',
        uploadTwoFiles: 'Нажмите чтобы загрузить документ и подпись документа (2 файла)',
        continueDownloadingTheFile: 'Выберите тип подписи для начала загрузки файла/файлов',
    },
};
