import { FormInstance } from 'antd';
import { CustomSelect } from 'shared/form-components/custom-select';

import styles from './address-form-block.module.css';

import { FormBlockLabel } from '~/components/application-tab/constants/general';
import { PRIVATE_ROOM_SUBTITLE } from '~/constants/private-room';
import { CountriesResponse, UserInfoResponse } from '~/rtk-queries/types/private-room';
import { CustomMaskInput } from '~/shared/form-components/custom-mask-input';
import { convertUserDataToArr } from '~/utils/convert-user-data-to-arr';
import { optionCountry } from '~/utils/get-country-data';

type AddressFormBlockProps<T> = {
    form: FormInstance<T>;
    countriesData: CountriesResponse;
    data?: UserInfoResponse;
};

export const AddressFormBlock = <T,>({ data, form, countriesData }: AddressFormBlockProps<T>) => (
    <div className={styles.container}>
        <div className={styles.applicationTabSubtitle}>{PRIVATE_ROOM_SUBTITLE.address}</div>
        <div className={styles.applicationTabBlock}>
            {convertUserDataToArr(data?.userInfo?.address).map(
                ({ name, label, isDisabled, mask, rules }) => {
                    const currentValue = form.getFieldValue(name[0]);

                    if (label === FormBlockLabel.region) {
                        return (
                            <CustomSelect
                                label={label}
                                keyName={name[0]}
                                key={label}
                                disabled={isDisabled}
                                options={optionCountry(countriesData.countries)}
                                rules={rules}
                            />
                        );
                    }

                    return (
                        <CustomMaskInput
                            label={label}
                            keyName={name[0]}
                            key={label}
                            mask={mask}
                            rules={rules}
                            disabled={isDisabled}
                            currentValue={currentValue}
                        />
                    );
                },
            )}
        </div>
    </div>
);
