import { useState } from 'react';
import classNames from 'classnames';

import { MCHTD } from '~/constants/mchd';

import styles from './mchd-tab.module.css';

export const MchdTab = () => {
    const [isLoadFrame, setIsLoadFrame] = useState(false);

    const onLoadFrame = () => {
        setIsLoadFrame(true);
    };

    return (
        <iframe
            src={MCHTD.src}
            className={classNames(styles.mchdTabFrame, isLoadFrame && styles.mchdTabFrameVisible)}
            title={MCHTD.title}
            onLoad={onLoadFrame}
        />
    );
};
