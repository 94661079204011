import { Doc, PrivateRoomInputsKeyName } from '~/constants/private-room';
import { generateRules } from '~/utils/generate-rules';

export const getRulesForDocuments = (keyName: string, docType: number) => {
    if (keyName === PrivateRoomInputsKeyName.docExpiryDate) {
        return [];
    }
    if (docType === Doc.RfPassport || keyName !== PrivateRoomInputsKeyName.docSeries) {
        return generateRules(keyName, docType);
    }

    return generateRules(PrivateRoomInputsKeyName.addressString, docType);
};
