import { FormInstance } from 'antd';

import { FormTypes } from '~/components/application-tab/types/form-types';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { Nullable } from '~/types/common';
import { removeLocalStorageItem } from '~/utils/local-storage';

export const cleanupAfterCertificateCreation = (
    form: FormInstance<Nullable<Partial<FormTypes>>>,
) => {
    removeLocalStorageItem(LOCAL_STORAGE.filtersForCertificates);
    removeLocalStorageItem(LOCAL_STORAGE.draftData);
    form.resetFields();
};
