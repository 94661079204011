import { ReactElement, useCallback, useState } from 'react';
import { Form } from 'antd';
import { FormItemProps } from 'antd/lib';
import classNames from 'classnames';

import styles from './custom-form-item.module.css';

import { ItemContainer } from '~/shared/item-container';
import { FormItemStatus } from '~/shared/types';

export type CustomFormItemHasNoChildren = Omit<FormItemProps, 'children'>;

export type CustomFormItemProps = CustomFormItemHasNoChildren & {
    children: ReactElement;
    getStatus?: (status: FormItemStatus) => void;
    className?: string;
};

export const CustomFormItem = ({
    children,
    getStatus,
    className,
    ...restProps
}: CustomFormItemProps) => {
    const [isStatusError, setIsStatusError] = useState(false);
    const getCurrentStatus = useCallback(
        (status: FormItemStatus) => {
            setIsStatusError(status === 'error');
            getStatus?.(status);
        },
        [getStatus],
    );

    return (
        <Form.Item
            {...restProps}
            className={classNames(className, {
                [styles.wrapperMargin]: !className,
                [styles.error]: isStatusError,
            })}
        >
            <ItemContainer getStatus={getCurrentStatus}>{children}</ItemContainer>
        </Form.Item>
    );
};
