import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DraftResponse } from '~/rtk-queries/types/certificates';

export type DraftState = {
    draftData: DraftResponse;
};

const initialState: DraftState = {
    draftData: {
        hasError: false,
        message: '',
        folderId: 0,
        templateId: 0,
        userInfoTemp: '',
    },
};

export const draftSlice = createSlice({
    name: 'draft',
    initialState,
    reducers: {
        setDraftData: (state, action: PayloadAction<DraftResponse>) => {
            state.draftData = action.payload;
        },
    },
    selectors: {
        draftSelector: (state) => state.draftData,
    },
});

export const { setDraftData } = draftSlice.actions;
export const { draftSelector } = draftSlice.selectors;
export const draftReducer = draftSlice.reducer;
