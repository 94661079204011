import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Divider, Dropdown, MenuProps, Space } from 'antd';
import classNames from 'classnames';

import { LogoBlock } from '../logo';

import styles from './header.module.css';

import { LOCAL_STORAGE } from '~/constants/local-storage';
import { ROUTES } from '~/constants/routes';
import { useAppDispatch } from '~/hooks';
import {
    useGetLogoutEsiaUrlMutation,
    usePostLogoutMutation,
} from '~/rtk-queries/endpoints/authentication';
import { useGetFrontConfigQuery } from '~/rtk-queries/endpoints/front-config';
import { useGetUserNameQuery } from '~/rtk-queries/endpoints/user-info';
import { interceptor } from '~/rtk-queries/interceptor';
import { HEADER } from '~/shared/header/constants/header';
import { clearStateOnLogout } from '~/store/auth/auth-slice';
import { FrontConfigKey } from '~/utils/get-front-config/type';

export const Header = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [postLogout] = usePostLogoutMutation();
    const [postLogoutEsia, { data: logoutEsiaData }] = useGetLogoutEsiaUrlMutation();

    const dispatch = useAppDispatch();

    const { data: frontConfig } = useGetFrontConfigQuery();

    const { data: userNameData } = useGetUserNameQuery();
    const name = userNameData?.name;

    const esiaLogin = Boolean(localStorage.getItem(LOCAL_STORAGE.isEsiaLogin));

    useEffect(() => {
        if (esiaLogin) postLogoutEsia();
    }, [esiaLogin, postLogoutEsia]);

    const logOutHandler = async () => {
        try {
            localStorage.clear();
            await postLogout().unwrap();
        } catch (err: unknown) {
            //
        } finally {
            dispatch(interceptor.util.resetApiState());
            dispatch(clearStateOnLogout());
            navigate(ROUTES.startPage, { replace: true });
        }
    };

    const items: MenuProps['items'] = [
        {
            label: (
                <div className={styles.buttonLogaut}>
                    {esiaLogin ? (
                        <Link
                            to={logoutEsiaData?.esiaLogoutUrl ?? '/'}
                            role='presentation'
                            onClick={logOutHandler}
                            className={styles.logoutBlock}
                        >
                            <LogoutOutlined />
                            <span className={styles.headerButton}>{HEADER.buttonTitle}</span>
                        </Link>
                    ) : (
                        <div
                            role='presentation'
                            onClick={logOutHandler}
                            className={styles.logoutBlock}
                        >
                            <LogoutOutlined />
                            <span className={styles.headerButton}>{HEADER.buttonTitle}</span>
                        </div>
                    )}
                </div>
            ),
            style: { padding: 0 },
            key: '0',
        },
    ];

    return (
        <header className={styles.header}>
            <LogoBlock type='dark' />
            <Space className={styles.infoBlock}>
                <a
                    target='_blank'
                    href={
                        frontConfig ? frontConfig[FrontConfigKey.PSBSignatureLink] : HEADER.linkInfo
                    }
                    rel='noopener noreferrer nofollow'
                >
                    <span className={styles.infoLink}>{HEADER.buttonInfo}</span>
                    <Divider className={styles.linkUnderline} />
                </a>
                <Dropdown
                    onOpenChange={() => setOpen(!open)}
                    menu={{ items }}
                    className={styles.dropdownMenu}
                    trigger={['click']}
                >
                    <Space>
                        <UserOutlined className={styles.userOutlined} />
                        <Space className={styles.nameBlock}>{name}</Space>
                        <DownOutlined
                            className={classNames(styles.outLined, { [styles.show]: open })}
                        />
                    </Space>
                </Dropdown>
            </Space>
        </header>
    );
};
