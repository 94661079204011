import { ReactNode } from 'react';

import styles from './form-wrapper.module.css';

type CustomFormWrapperProps = {
    children: ReactNode;
    header?: ReactNode;
    footer?: ReactNode;
};
export const FormWrapper = ({ children, header, footer }: CustomFormWrapperProps) => (
    <div className={styles.container}>
        <header>{header}</header>
        <main className={styles.main}>{children}</main>
        <footer className={styles.footer}>{footer}</footer>
    </div>
);
