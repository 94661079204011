import { DatePicker } from 'antd';
import { Rule } from 'antd/es/form';
import { CustomFormItem } from 'shared/form-components/custom-form-item';

import styles from './custom-data-picker.module.css';

import { buddhistLocale } from '~/constants/date-format';
import { DATE_FORMAT } from '~/constants/general';
import { useCustomSelectProps } from '~/hooks/use-custom-props';
import { CustomTooltip } from '~/shared/custom-tooltip';

type CustomDataPickerProps = {
    currentValue?: string;
    label?: string;
    keyName?: string;
    rules?: Rule[];
    disabled?: boolean;
};

export const CustomDataPicker = ({
    currentValue,
    label,
    keyName,
    rules,
    disabled,
    ...restProps
}: CustomDataPickerProps) => {
    const { titleForTooltip, placeholder, backgroundColorDisabled } = useCustomSelectProps(
        currentValue,
        disabled || false,
        label,
    );

    return (
        <CustomTooltip title={titleForTooltip}>
            <CustomFormItem
                name={keyName}
                label={label}
                className={styles.inputItem}
                rules={rules}
                {...restProps}
            >
                <DatePicker
                    format={{
                        format: DATE_FORMAT,
                        type: 'mask',
                    }}
                    style={backgroundColorDisabled}
                    placeholder={placeholder}
                    locale={buddhistLocale}
                    className={styles.input}
                    disabled={disabled}
                />
            </CustomFormItem>
        </CustomTooltip>
    );
};
