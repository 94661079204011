import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { AccessLoaderModals } from './components/access-loader-modals';

import { ROUTES } from '~/constants/routes';
import { stepName } from '~/constants/step-name';
import { StepRedirect } from '~/hoc/step-redirect';
import { useEnterPage } from '~/hooks/use-enter-page';
import { useGetEsiaLoginQuery } from '~/rtk-queries/endpoints/authentication';
import { useGetFrontConfigQuery } from '~/rtk-queries/endpoints/front-config';
import { EnterScreen } from '~/shared/enter-screen';
import { StepParams } from '~/types/router';

type EnterProps = {
    isPluginInstalled?: boolean;
};

export const Enter: FC<EnterProps> = ({ isPluginInstalled = true }) => {
    const { stepName: enterStep } = useParams<StepParams>();
    const { data } = useGetEsiaLoginQuery();
    const { data: frontConfig } = useGetFrontConfigQuery();

    const {
        isOpenModalAccessConfirmation,
        onClickElectronicSignature,
        linkInstall,
        onClickCheckElectronicSignature,
        onClickDownloadPlugin,
        onClickInstallExtension,
        onClickOtherLogin,
        setIsOpenModalAccessConfirmation,
        cadesStatus,
    } = useEnterPage(frontConfig);

    return isPluginInstalled || enterStep === stepName.three || !cadesStatus ? (
        <StepRedirect>
            <EnterScreen
                onClickButtonBottom={
                    enterStep === stepName.one ? onClickElectronicSignature : onClickOtherLogin
                }
                onClickButtonTop={
                    (enterStep === stepName.two && onClickInstallExtension) ||
                    (enterStep === stepName.three && onClickDownloadPlugin) ||
                    ((enterStep === stepName.four || enterStep === stepName.five) &&
                        onClickCheckElectronicSignature) ||
                    undefined
                }
                linkEsia={enterStep === stepName.one ? data?.esiaAuthUrl : null}
                linkInstall={linkInstall}
                frontConfig={frontConfig}
            />

            <AccessLoaderModals
                isOpenModalAccessConfirmation={isOpenModalAccessConfirmation}
                setIsOpenModalAccessConfirmation={setIsOpenModalAccessConfirmation}
            />
        </StepRedirect>
    ) : (
        <Navigate to={`${ROUTES.stepsRootPage}/${stepName.three}`} replace={true} />
    );
};
