import { rulesInput } from './rules-input';

import {
    INPUTS_IF_DISPLAYNAME_NULL,
    MASK_PATTERN,
    PRIVATE_ROOM,
    PRIVATE_ROOM_MAIN,
    PrivateRoomInputsKeyName,
} from '~/constants/private-room';
import { UserData, UserInfoData, UserInfoDataValue } from '~/types/user-info';

export const convertUserDataMainToArr = (main?: UserInfoData) => {
    const arr = [] as UserData[];
    const arrSorted = [] as UserData[];

    if (main) {
        Object.entries(main).forEach(
            ([key, value]: [
                key: PrivateRoomInputsKeyName | string,
                value: UserInfoDataValue | string | null,
            ]) => {
                if (key === 'birthPlace') {
                    arr.push({
                        name: [key],
                        label:
                            INPUTS_IF_DISPLAYNAME_NULL.find(({ keyName }) => keyName === key)
                                ?.label ?? '',
                        isDisabled: false,
                        mask:
                            INPUTS_IF_DISPLAYNAME_NULL.find(({ keyName }) => keyName === key)
                                ?.mask ?? '',
                        type:
                            INPUTS_IF_DISPLAYNAME_NULL.find(({ keyName }) => keyName === key)
                                ?.type ?? 'text',
                        rules: rulesInput({
                            required: false,
                            regex: '',
                        }),
                    });
                }
                if (value && typeof value !== 'string') {
                    arr.push({
                        name: [key],
                        label: value.displayName,
                        isDisabled: !value.enabled ?? false,
                        mask:
                            PRIVATE_ROOM.inputs.personalData.find(({ keyName }) => keyName === key)
                                ?.mask ?? '',
                        rules: rulesInput({
                            required: value.required,
                            regex: key === 'mobile' ? MASK_PATTERN.phoneRegExp : value.regex,
                            maxLength: value.maxLength,
                            minLength: value.minLength,
                        }),
                    });
                }
            },
        );

        PRIVATE_ROOM_MAIN.map((item) =>
            arr.forEach((itemArr) => {
                if (item === itemArr.label) {
                    arrSorted.push(itemArr);
                }
            }),
        );
    }

    return arrSorted;
};
