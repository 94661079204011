import { RcFile } from 'antd/es/upload';

export const convertToBase64 = async (file?: File | RcFile): Promise<null | string> => {
    if (!file) {
        return null;
    }

    try {
        return await new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.addEventListener('loadend', (e) => {
                const result = e.target?.result;

                if (typeof result === 'string') {
                    const base64 = result.split(',')[1];

                    resolve(base64);
                } else {
                    resolve(null);
                }
            });

            reader.addEventListener('error', reject);

            reader.readAsDataURL(file);
        });
    } catch (err) {
        return null;
    }
};
