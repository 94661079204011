import { getTitleWithSerialNumber } from '../get-title-with-serial-number';
import { transformDateToLocale } from '../transform-date-to-locale';

import { CERTIFICATES_STATUS_INFO } from '~/constants/certificates';
import { LifeStatus } from '~/constants/certificates-table';
import { CertificateData } from '~/utils/transform-certificates-data/type';

// Утилита, которая трансфортирует данные для отображения в таблице с перечнем сертификатов

export const transformCertificateData = (data: CertificateData) => {
    const { lifeStatus, lastEditDate, title, status } = data;

    const { color, titleStatus, actionsVariant, documents } = CERTIFICATES_STATUS_INFO[status];
    const keyVariant = {
        [LifeStatus.preApplication]:
            lifeStatus === LifeStatus.preApplication ? data.lkRequestId.toString() : '',
        [LifeStatus.application]: `${lastEditDate}+${title}`,
        [LifeStatus.certificate]: lifeStatus === LifeStatus.certificate ? data.certificateId : '',
        [LifeStatus.revocation]: lifeStatus === LifeStatus.revocation ? data.certificateId : '',
    };

    return {
        ...data,
        title: getTitleWithSerialNumber(title, data),
        key: keyVariant[lifeStatus],
        status: { color, titleStatus, statusCode: status },
        lastEditDate: transformDateToLocale(lastEditDate),
        actionsVariant,
        documents,
    };
};
