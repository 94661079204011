import { FormInstance } from 'antd';
import { CustomInput } from 'shared/form-components/custom-input';

import styles from './other-form-block.module.css';

import { PRIVATE_ROOM_SUBTITLE } from '~/constants/private-room';
import { UserInfoResponse } from '~/rtk-queries/types/private-room';
import { convertUserDataToArr } from '~/utils/convert-user-data-to-arr';

type OtherFormBlockProps<T> = {
    form: FormInstance<T>;
    data?: UserInfoResponse;
};

export const OtherFormBlock = <T,>({ data, form }: OtherFormBlockProps<T>) => (
    <div className={styles.container}>
        <div className={styles.applicationTabSubtitle}>{PRIVATE_ROOM_SUBTITLE.other}</div>
        <div className={styles.applicationTabBlock}>
            {convertUserDataToArr(data?.userInfo?.other).map(
                ({ name, label, isDisabled, rules }) => {
                    const currentValue = form.getFieldValue(name[0]);

                    return (
                        <CustomInput
                            label={label}
                            keyName={name[0]}
                            key={label}
                            rules={rules}
                            disabled={isDisabled}
                            currentValue={currentValue}
                        />
                    );
                },
            )}
        </div>
    </div>
);
