import { Dispatch, Fragment, SetStateAction } from 'react';
import { DeleteOutlined } from '@ant-design/icons';

import { getModalAnswerText, getModalTitleText } from '../helpers/get-modal-text';

import styles from '../application-tab.module.css';

import { ViewAnswerModal } from '~/components/view-answer-modal';
import { DocIcon } from '~/icons/doc-icon';
import { CustomButton } from '~/shared/custom-button';
import { CustomModal } from '~/shared/custom-modal';
import { CommonResponse } from '~/types/common';

type FormModalProps = {
    isOpenSuccessModal: boolean;
    setIsOpenSuccessModal: Dispatch<SetStateAction<boolean>>;
    openSuccessModal: () => void;
    isErrorModal: boolean;
    selectedTemplateName: string;
    isDraftError?: boolean;
    createData?: CommonResponse;
};

export const FormModal = ({
    isOpenSuccessModal,
    setIsOpenSuccessModal,
    openSuccessModal,
    isErrorModal,
    isDraftError,
    createData,
    selectedTemplateName,
}: FormModalProps) => (
    <CustomModal
        open={isOpenSuccessModal}
        closeIcon={true}
        setOpenModal={setIsOpenSuccessModal}
        isClose={isOpenSuccessModal}
        footer={[
            <CustomButton className='enterPageButtonYes' key='ok' onClick={openSuccessModal}>
                {isDraftError ? 'УДАЛИТЬ ЧЕРНОВИК' : 'ОК'}
            </CustomButton>,
        ]}
    >
        <ViewAnswerModal
            isError={isErrorModal}
            title={
                <Fragment>
                    {isDraftError ? <DeleteOutlined className={styles.icon} /> : <DocIcon />}
                    {getModalTitleText(createData)}
                </Fragment>
            }
            text={getModalAnswerText(createData, selectedTemplateName)}
        />
    </CustomModal>
);
