import { FC } from 'react';
import { Form, FormInstance } from 'antd';

import { MAX_FILE_QUANTITY, MAX_SIZE_FILE_MB } from '../../constants/file-upload';
import { SignatureSelection } from '../../constants/signature-selection-options';
import { CheckDocumentsFields } from '../../types/form-types';

import { FORMS_DATA } from '~/constants/forms-data';
import { FormUpload } from '~/shared/files-upload';

type CheckDocumentUploadProps = {
    form: FormInstance<unknown>;
    disabledFormElements?: boolean;
    loading?: boolean;
};

export const CheckDocumentUpload: FC<CheckDocumentUploadProps> = ({
    disabledFormElements = false,
    loading,
    form,
}) => {
    const currentSignatureSelect = Form.useWatch(CheckDocumentsFields.signatureSelection, form);

    const needOneFileUpload =
        currentSignatureSelect && currentSignatureSelect === SignatureSelection.pinnedSignature;

    const disabledUpload = disabledFormElements && !needOneFileUpload;

    return currentSignatureSelect ? (
        <FormUpload
            buttonText={
                needOneFileUpload
                    ? FORMS_DATA.checkDocumentForm.uploadOneFile
                    : FORMS_DATA.checkDocumentForm.uploadTwoFiles
            }
            name={CheckDocumentsFields.content}
            title={<h4>{FORMS_DATA.checkDocumentForm.uploadTitle}</h4>}
            fileListWidth={300}
            disabledUploadButton={disabledUpload}
            isRequired={true}
            fileListBlock={true}
            hideUploadButtonAfterLastFileUploaded={true}
            isLoading={loading}
            multiple={!needOneFileUpload}
            maxCount={needOneFileUpload ? 1 : MAX_FILE_QUANTITY}
            maxFileSizeMb={MAX_SIZE_FILE_MB}
            minCount={needOneFileUpload ? undefined : MAX_FILE_QUANTITY}
        />
    ) : null;
};
