import { createArrayLoader, createEnvironment } from 'twing';

import { PrintTemplates, statusesRevokeInProgress } from '~/constants/certificates';
import { LifeStatus } from '~/constants/certificates-table';
import { REVOKE_REASON_PRINT, RevReasonPKI } from '~/constants/revoke-certificate';
import { useLazyGetNamePolicyQuery } from '~/rtk-queries/endpoints/name-policy';
import {
    useLazyGetPKICertificateQuery,
    useLazyGetPKICertRequestQuery,
    useLazyGetPKIRevokeQuery,
    useLazyGetPKIUserQuery,
} from '~/rtk-queries/endpoints/pki';
import {
    useLazyGetInitForPrintQuery,
    useLazyGetPrintedTemplateQuery,
} from '~/rtk-queries/endpoints/template';
import { Context } from '~/rtk-queries/types/pki';
import { InitPrint } from '~/rtk-queries/types/template';
import { CurrentPageTable } from '~/utils/transform-certificates-data/type';

export const usePrintDocument = () => {
    const [getPrintedTemplate, { isFetching: isFetchingTemplate }] =
        useLazyGetPrintedTemplateQuery();
    const [getPrintedTemplateHeader, { isFetching: isFetchingHeader }] =
        useLazyGetPrintedTemplateQuery();
    const [getInitForPrint, { isFetching: isFetchingInit }] = useLazyGetInitForPrintQuery();
    const [getPKICertRequest, { isFetching: isFetchingRequest }] = useLazyGetPKICertRequestQuery();
    const [getPKICertificate, { isFetching: isFetchingCertificate }] =
        useLazyGetPKICertificateQuery();
    const [getPKIUser, { isFetching: isFetchingUser }] = useLazyGetPKIUserQuery();
    const [getNameAttributes, { isFetching: isFetchingOidNames }] = useLazyGetNamePolicyQuery();
    const [getRevokedCertificateData, { isFetching: isFetchingRevokedCertificateData }] =
        useLazyGetPKIRevokeQuery();

    const isFetchingPrintData =
        isFetchingHeader ||
        isFetchingInit ||
        isFetchingOidNames ||
        isFetchingRequest ||
        isFetchingTemplate ||
        isFetchingUser ||
        isFetchingCertificate ||
        isFetchingRevokedCertificateData;

    const handlePreApplicationStatus = async (
        rowData: CurrentPageTable,
        context: Context,
    ): Promise<Context> => {
        if (rowData?.lifeStatus !== LifeStatus.preApplication) return context;
        const { data } = await getPKIUser(rowData.pkiUserId);

        return { ...context, user: data?.pkiUser };
    };

    const handleApplicationStatus = async (
        rowData: CurrentPageTable,
        context: Context,
    ): Promise<Context> => {
        if (rowData?.lifeStatus !== LifeStatus.application) return context;
        const { data: certRequest } = await getPKICertRequest(rowData.certRequestId);

        if (certRequest) {
            const { userId } = certRequest.pkiCertRequest;
            const { data: userData } = await getPKIUser(userId);

            return {
                ...context,
                user: userData?.pkiUser,
                certRequest: certRequest.pkiCertRequest,
            };
        }

        return context;
    };

    const handleCertificateStatus = async (
        rowData: CurrentPageTable,
        context: Context,
    ): Promise<Context> => {
        if (rowData?.lifeStatus !== LifeStatus.certificate) return context;

        const { data: certificate } = await getPKICertificate(rowData.certificateId);

        if (!certificate) return context;

        const { userId } = certificate.pkiCertificate;
        const { data: userData } = await getPKIUser(userId);

        const revReason =
            REVOKE_REASON_PRINT[certificate.pkiCertificate.revocationReason as RevReasonPKI] ??
            REVOKE_REASON_PRINT[RevReasonPKI.Unspecified];

        return {
            ...context,
            user: userData?.pkiUser,
            certificate: certificate.pkiCertificate,
            revReason,
            // уточнить про revoke
        };
    };

    const handleCertificateStatusRevocation = async (
        rowData: CurrentPageTable,
        context: Context,
    ): Promise<Context> => {
        if (rowData?.lifeStatus !== LifeStatus.revocation) return context;

        if (
            rowData.status &&
            statusesRevokeInProgress.includes(rowData.status.statusCode) &&
            rowData?.revokeRequestId
        ) {
            const { data: certificate } = await getRevokedCertificateData(rowData.revokeRequestId);

            if (!certificate || !certificate.certificateId) return context;

            const { data: certificateData } = await getPKICertificate(certificate.certificateId);
            const { userId } = certificate;
            const { data: userData } = await getPKIUser(userId);
            const revReason =
                REVOKE_REASON_PRINT[certificate.revocationReason as RevReasonPKI] ??
                REVOKE_REASON_PRINT[RevReasonPKI.Unspecified];

            return {
                ...context,
                user: userData?.pkiUser,
                certificate: certificateData?.pkiCertificate,
                revReason,
            };
        }

        return context;
    };

    const lifeStatusActions: Record<
        LifeStatus,
        (rowData: CurrentPageTable, context: Context) => Promise<Context>
    > = {
        [LifeStatus.preApplication]: handlePreApplicationStatus,
        [LifeStatus.application]: handleApplicationStatus,
        [LifeStatus.certificate]: handleCertificateStatus,
        [LifeStatus.revocation]: handleCertificateStatusRevocation,
    };

    const createContext = async (
        rowData: CurrentPageTable,
        initForPrint?: InitPrint,
        nameAttributes?: Record<string, string>,
    ): Promise<Context> => {
        const initialContext: Context = {
            dictionary: initForPrint?.dictionary,
            timeZone: initForPrint?.config.TIME_ZONE,
            oidNames: nameAttributes,
        };

        try {
            if (rowData && lifeStatusActions[rowData.lifeStatus]) {
                return await lifeStatusActions[rowData.lifeStatus](rowData, initialContext);
            }
        } catch (error) {
            console.error('Error createContext for print:', error);
        }

        return initialContext;
    };

    const onPrintClick = async (templateId: PrintTemplates, rowData: CurrentPageTable) => {
        try {
            const [
                { data: template },
                { data: header },
                { data: nameAttributes },
                { data: initForPrint },
            ] = await Promise.all([
                getPrintedTemplate(templateId),
                getPrintedTemplateHeader(PrintTemplates.Header),
                getNameAttributes(),
                getInitForPrint(),
            ]);

            const context = await createContext(rowData, initForPrint, nameAttributes);

            const loader = createArrayLoader({
                'index.twig': template as string,
                tplHeader: header as string,
            });
            const environment = createEnvironment(loader);

            environment
                .render('index.twig', context)
                .then((output) => {
                    const newWindow = window.open('', '_blank');

                    if (newWindow) {
                        newWindow.document.write(output);
                        newWindow.document.close();
                        setTimeout(() => {
                            newWindow.print();
                        }, 500);
                    }
                })
                .catch((error) => {
                    console.error('Error rendering template:', error);
                });
        } catch (e) {
            console.error('Error rendering:', e);
        }
    };

    return {
        onPrintClick,
        isFetchingPrintData,
    };
};
