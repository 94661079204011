export enum PluginError {
    isNotInPersonalStorage = '0x80092004', // Объект или свойство не найдено. (0x80092004) => Сертификат не установлен в личное хранилище
    cancel = '0x8010006E', // Действие было отменено пользователем. (0x8010006E) => Действие было отменено пользователем
    cancelInInstall = '0x000004C7', // 'Операция была отменена пользователем. (0x000004C7)' => Действие было отменено пользователем
    keyUndefined = '0x8009000D', // ключа не существует
    incorrectParameter = '0x80070057', // Параметр задан неверно
    lostConnection = 'Lost connection to extension', // при отключении CryptoPro Extension for CAdES
    containerNotFound = '0x80090016',
    internalError = '0x80090020', // Внутренняя ошибка
}

export const PSB_CONTAINER_NAME = 'psb';

export const GENERATE_KEYS_DATA = {
    showProtectUI: false,
    signRequest: false, // подписать запрос на сертификат
    signByThumbprint: null, // MOCK_OPERATOR_THUMBPRINT
    addIdentificationKind: false,
    identificationKind: 0,
    addTemplate: true,
    reuseKey: false, // переиспользовать или сгенерировать новый
    limitToEncipherment: true, // предел шифрования
};
