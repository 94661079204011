import { generateRules } from '../generate-rules';
import { getLocalStorageItem } from '../local-storage';

import { getRulesForDocuments } from '~/components/application-tab/helpers/get-rules-for-documents';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import {
    Doc,
    docNumberLabel,
    PRIVATE_ROOM,
    PrivateRoomInput,
    PrivateRoomInputsKeyName,
} from '~/constants/private-room';
import { DocsData } from '~/types/user-info';
import { DocumentTemplateType, UserInfoDataDocuments } from '~/utils/convert-docs-to-arr/type';

export const ConvertDocsToArr = (
    docType: number,
    documentsTemplate: DocumentTemplateType[],
    draftType?: number,
    docs?: UserInfoDataDocuments[] | null,
) => {
    const arr = [] as DocsData[];

    const esiaLogin = Boolean(getLocalStorageItem(LOCAL_STORAGE.isEsiaLogin));

    if (docs) {
        docs?.forEach((doc) => {
            const docFields = Object.entries(doc).filter(([key]) => key !== 'type');

            if (docType === doc.type) {
                docFields.forEach(([key, value]) => {
                    const isEmptyIssueId =
                        key === PrivateRoomInputsKeyName.docIssueId &&
                        !value &&
                        docType > Doc.RfPassport;

                    let rules = getRulesForDocuments(key, docType);

                    let mask =
                        PRIVATE_ROOM.inputs.identityDocuments.find(({ keyName }) => keyName === key)
                            ?.mask[docType - 1] ?? '';

                    if (isEmptyIssueId) {
                        rules = [generateRules(key, Doc.RfPassport)[1]];
                        mask =
                            PRIVATE_ROOM.inputs.identityDocuments.find(
                                ({ keyName }) => keyName === PrivateRoomInputsKeyName.docIssueId,
                            )?.mask[Doc.RfPassport - 1] ?? '';
                    }

                    arr.push({
                        name: [key],
                        label:
                            key === PrivateRoomInputsKeyName.docNumber && docType > Doc.FidDoc
                                ? docNumberLabel
                                : PRIVATE_ROOM.inputs.identityDocuments.find(
                                      ({ keyName }) => keyName === key,
                                  )?.label ?? '',
                        type:
                            PRIVATE_ROOM.inputs.identityDocuments.find(
                                ({ keyName }) => keyName === key,
                            )?.type ?? PrivateRoomInput.text,
                        mask,
                        isDisabled: docType === draftType && !esiaLogin ? false : !!value,
                        rules,
                    });
                });
            }
        });

        const arrType: (number | undefined)[] = [];

        docs?.forEach((doc) => {
            arrType.push(doc.type);
        });
        if (!arrType.includes(docType)) {
            documentsTemplate.forEach(({ keyName, label, type, mask }) => {
                arr.push({
                    name: [keyName],
                    label:
                        keyName === PrivateRoomInputsKeyName.docNumber && docType > Doc.FidDoc
                            ? docNumberLabel
                            : label,
                    type,
                    mask: mask[docType - 1],
                    isDisabled: false,
                    rules: getRulesForDocuments(keyName, docType),
                });
            });
        }
    }

    return arr;
};
