import { ApiGroupNames } from '../constants/api-group-names';
import { EndpointNames } from '../constants/endpoint-names';
import { Tags } from '../constants/tags';
import { transformErrorWithAlert } from '../helpers/transform-error-with-alert';
import { interceptor } from '../interceptor';

import { AlertMessage } from '~/constants/alert-message';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { API } from '~/rtk-queries/constants/api';
import {
    EsiaAuthUrlResponse,
    LoginByCertificateRequest,
    LoginByCertificateResponse,
    LoginByEsiaRequest,
    LoginByEsiaResponse,
    LogoutEsiaResponse,
    SessionDataResponse,
    SessionParams,
} from '~/rtk-queries/types/auth';
import { CommonResponse } from '~/types/common';

export const authentication = interceptor
    .enhanceEndpoints({
        addTagTypes: [Tags.ENSURE_SESSION, Tags.ESIA_LOGIN_URL, Tags.USER_NAME],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            ensureSession: builder.query<CommonResponse<SessionDataResponse>, SessionParams>({
                query: ({ id }) => ({
                    url: API.EnsureSession,
                    method: 'POST',
                    apiGroupName: ApiGroupNames.AUTHENTICATION,
                    name: EndpointNames.POST_ENSURE_SESSION,
                    params: { id },
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                extraOptions: { auth: true, ensureSession: true },
                providesTags: [Tags.ENSURE_SESSION],
            }),
            getEsiaLogin: builder.query<CommonResponse<EsiaAuthUrlResponse>, void>({
                query: () => ({
                    url: API.getEsiaLoginUrl,
                    method: 'GET',
                    apiGroupName: ApiGroupNames.AUTHENTICATION,
                    name: EndpointNames.GET_ESIA_LOGIN,
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                providesTags: [Tags.ESIA_LOGIN_URL],
            }),
            postLoginByEsia: builder.mutation<
                CommonResponse<LoginByEsiaResponse>,
                LoginByEsiaRequest
            >({
                query: (data) => ({
                    url: API.loginByEsia,
                    method: 'POST',
                    apiGroupName: ApiGroupNames.AUTHENTICATION,
                    name: EndpointNames.POST_LOGIN_BY_ESIA,
                    body: data,
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                extraOptions: { auth: true, ensureSession: false },
                async onQueryStarted(_, { queryFulfilled }) {
                    try {
                        await queryFulfilled;
                        localStorage.setItem(LOCAL_STORAGE.isEsiaLogin, String(true));
                    } catch (err: unknown) {
                        //
                    }
                },
                invalidatesTags: (_, error) =>
                    error ? [Tags.ENSURE_SESSION, Tags.ESIA_LOGIN_URL] : [Tags.USER_NAME],
            }),
            postLogout: builder.mutation<CommonResponse, void>({
                query: () => ({
                    url: API.logout,
                    method: 'POST',
                    apiGroupName: ApiGroupNames.AUTHENTICATION,
                    name: EndpointNames.POST_LOGOUT,
                }),
            }),
            getLogoutEsiaUrl: builder.mutation<CommonResponse<LogoutEsiaResponse>, void>({
                query: () => ({
                    url: API.logoutEsia,
                    method: 'POST',
                    apiGroupName: ApiGroupNames.AUTHENTICATION,
                    name: EndpointNames.POST_LOGOUT_ESIA,
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
            }),
            refreshToken: builder.mutation<
                LoginByEsiaResponse,
                { sessionId: number; refreshToken: string }
            >({
                query: (body) => ({
                    url: API.refreshToken,
                    method: 'POST',
                    apiGroupName: ApiGroupNames.AUTHENTICATION,
                    name: EndpointNames.REFRESH_TOKEN,
                    body,
                }),
                extraOptions: { auth: true, ensureSession: false },
            }),
            authByCert: builder.mutation<
                CommonResponse<LoginByCertificateResponse>,
                LoginByCertificateRequest
            >({
                query: (body) => ({
                    url: API.loginByCertificate,
                    method: 'POST',
                    apiGroupName: ApiGroupNames.AUTHENTICATION,
                    name: EndpointNames.AUTH_BY_CERT,
                    body,
                }),
                extraOptions: { auth: true, ensureSession: false },
                transformErrorResponse: ({ data }) => data as LoginByCertificateResponse,
                invalidatesTags: (_, error) =>
                    error ? [Tags.ENSURE_SESSION, Tags.ESIA_LOGIN_URL] : [Tags.USER_NAME],
            }),
        }),
    });

export const {
    useEnsureSessionQuery,
    useGetEsiaLoginQuery,
    usePostLoginByEsiaMutation,
    usePostLogoutMutation,
    useGetLogoutEsiaUrlMutation,
    useAuthByCertMutation,
    useRefreshTokenMutation,
} = authentication;
