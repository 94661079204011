import { useState } from 'react';
import { Popconfirm, Tooltip, Typography } from 'antd';

import { TOOLTIP_TEXT_FOR_ARCHIVE, TOOLTIP_TEXT_FOR_SAVE } from '../constants/general';

import styles from './actions.module.css';

import { ActionVariantsType, CERTIFICATES_ACTIONS } from '~/constants/certificates';
import { LifeStatus } from '~/constants/certificates-table';
import { isArrayWithItems } from '~/utils/is-array-with-items';
import { CurrentPageTable } from '~/utils/transform-certificates-data/type';

export const Actions = ({
    item,
    actionVariantHandler,
}: {
    item: CurrentPageTable;
    actionVariantHandler: (action: ActionVariantsType, data: CurrentPageTable) => void;
}) => {
    const [visibleTooltip, setVisibleTooltip] = useState(false);

    const onOpenChangeHandler = (visible: boolean, action: ActionVariantsType) => {
        setVisibleTooltip(visible && action === ActionVariantsType.sendToArchive);
    };

    const isShowViewAnswerAction =
        isArrayWithItems(item.details) && item.lifeStatus === LifeStatus.preApplication;

    const isSaveToFileAction = item?.actionsVariant?.includes(ActionVariantsType.saveToFile);

    return (
        <div className={styles.certActions}>
            <div className={styles.columnActions}>
                {isShowViewAnswerAction && (
                    <Typography.Link
                        className={styles.certAction}
                        onClick={() => actionVariantHandler(ActionVariantsType.viewAnswer, item)}
                    >
                        {CERTIFICATES_ACTIONS[ActionVariantsType.viewAnswer]}
                    </Typography.Link>
                )}
                {item?.actionsVariant?.map((action) =>
                    action === ActionVariantsType.deleteDraft ? (
                        <Popconfirm
                            key={action}
                            title='Удалить черновик?'
                            onConfirm={() => actionVariantHandler(action, item)}
                            onCancel={() => {}}
                            okText='Да'
                            cancelText='Нет'
                        >
                            <Typography.Link className={styles.certAction}>
                                {CERTIFICATES_ACTIONS[action]}
                            </Typography.Link>
                        </Popconfirm>
                    ) : (
                        action !== ActionVariantsType.saveToFile && (
                            <Tooltip
                                fresh={true}
                                placement='left'
                                key={action}
                                title={TOOLTIP_TEXT_FOR_ARCHIVE}
                                open={action === ActionVariantsType.sendToArchive && visibleTooltip}
                                onOpenChange={(open) => onOpenChangeHandler(open, action)}
                            >
                                <Typography.Link
                                    className={styles.certAction}
                                    onClick={() => actionVariantHandler(action, item)}
                                >
                                    {CERTIFICATES_ACTIONS[action]}
                                </Typography.Link>
                            </Tooltip>
                        )
                    ),
                )}
            </div>

            {isSaveToFileAction && (
                <Tooltip
                    align={{ offset: [0, -8] }}
                    className={styles.sideAction}
                    trigger={['hover']}
                    title={TOOLTIP_TEXT_FOR_SAVE}
                    zIndex={10}
                >
                    <div className={styles.sideAction}>
                        <Typography.Link
                            onClick={() =>
                                actionVariantHandler(ActionVariantsType.saveToFile, item)
                            }
                        >
                            {CERTIFICATES_ACTIONS[ActionVariantsType.saveToFile]}
                        </Typography.Link>
                    </div>
                </Tooltip>
            )}
        </div>
    );
};
