import { ColumnsType } from 'antd/es/table';
import { FilterDropdownProps } from 'antd/es/table/interface';

import { Actions } from '../actions/actions';
import { Documents } from '../documents';
import { RangePickerDropdown } from '../range-picker-dropdown';

import {
    ActionVariantsType,
    CERTIFICATES_STATUS_INFO,
    PrintTemplates,
    StatusCode,
} from '~/constants/certificates';
import { CERTIFICATES_COLUMNS, CertificatesColumnsName } from '~/constants/certificates-table';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { CertificatesTitle } from '~/shared/certificates-title';
import { CustomTag } from '~/shared/custom-tag';
import { CurrentPageTable } from '~/utils/transform-certificates-data/type';
import { transformTitleToCertificateTable } from '~/utils/transform-title-to-certificate-table';

type ReturnColumnsForCertificatesTableType = {
    actionVariantHandler: (action: ActionVariantsType, data: CurrentPageTable) => void;
    printHandler: (templateId: PrintTemplates, data: CurrentPageTable) => void;
    allTitles: Array<string>;
    currentPage: CurrentPageTable[];
};
export const returnColumnsForCertificatesTable = ({
    allTitles,
    printHandler,
    actionVariantHandler,
    currentPage,
}: ReturnColumnsForCertificatesTableType): ColumnsType<CurrentPageTable> => {
    const filterDropdown = ({ ...props }: FilterDropdownProps) => (
        <RangePickerDropdown filterName={LOCAL_STORAGE.filtersForCertificates} {...props} />
    );

    return [
        {
            key: CertificatesColumnsName.title,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.title],
            dataIndex: CertificatesColumnsName.title,
            filters: allTitles?.map((item) => ({
                text: transformTitleToCertificateTable(item, currentPage),
                value: item,
            })),
            filterOnClose: false,
            filterSearch: true,
            sorter: true,
            render: (text) => <CertificatesTitle title={text} />,
        },
        {
            key: CertificatesColumnsName.statusTag,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.statusTag],
            dataIndex: CertificatesColumnsName.statusTag,
            filters: Object.keys(CERTIFICATES_STATUS_INFO).map((element) => ({
                text: CERTIFICATES_STATUS_INFO[Number(element) as StatusCode].titleStatus,
                value: Number(element),
            })),
            filterOnClose: false,
            filterSearch: true,
            sorter: true,
            render: (item) => <CustomTag title={item.titleStatus} color={item.color} />,
        },

        {
            key: CertificatesColumnsName.lastEditDate,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.lastEditDate],
            dataIndex: CertificatesColumnsName.lastEditDate,
            filterDropdown,
            filterSearch: true,
            sorter: true,
            width: 'var(--width-for-column-150)',
        },
        {
            key: CertificatesColumnsName.actionsVariant,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.actionsVariant],
            dataIndex: CertificatesColumnsName.actionsVariant,
            render: (_, item) => (
                <Actions item={item} actionVariantHandler={actionVariantHandler} />
            ),
            width: 'var(--width-for-column-220)',
        },
        {
            key: CertificatesColumnsName.documents,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.documents],
            dataIndex: CertificatesColumnsName.documents,
            render: (variants, item) =>
                variants.map((document: PrintTemplates) => (
                    <Documents
                        key={document}
                        item={item}
                        printHandler={printHandler}
                        variant={document}
                    />
                )),
            width: 'var(--width-for-column-220)',
        },
    ];
};
