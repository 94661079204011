export enum BrauserName {
    chrome = 'Chrome',
    edg = 'Edg',
    yaBrowser = 'YaBrowser',
    opera = 'Opera',
    firefox = 'Firefox',
    safari = 'Safari',
    ie = 'IE',
    msie = 'MSIE',
}

export enum PlatformName {
    macOS = 'macOS',
    windows = 'Windows',
    aurora = 'Aurora',
    linux = 'Linux',
}

export const LINK_CADES_PLAGIN = {
    [BrauserName.chrome]:
        'https://chrome.google.com/webstore/detail/cryptopro-extension-for-c/iifchhfnnmpdbibifmljnfjhpififfog',
    [BrauserName.edg]:
        'https://chromewebstore.google.com/search/CryptoPro%20Extension%20for%20CAdES%20Browser%20Plug-in',
    [BrauserName.yaBrowser]:
        'https://addons.opera.com/en/extensions/details/cryptopro-extension-for-cades-browser-plug-in',
    [BrauserName.opera]:
        'https://addons.opera.com/en/extensions/details/cryptopro-extension-for-cades-browser-plug-in',
    [BrauserName.firefox]:
        'https://www.cryptopro.ru/sites/default/files/products/cades/extensions/firefox_cryptopro_extension_latest.xpi',
    [BrauserName.safari]: 'https://www.cryptopro.ru/products/cades/plugin/get_2_0',
    [BrauserName.ie]: 'https://www.cryptopro.ru/products/cades/plugin/get_2_0',
    [BrauserName.msie]: 'https://www.cryptopro.ru/products/cades/plugin/get_2_0',
    default: 'https://www.cryptopro.ru/products/cades/plugin/get_2_0',
};

// TODO Ссылки пока решают какие необходимо вставить, поэтому нужно это

// export const LINK_PLATFORM_INSTRUCTIONS = {
//     [PlatformName.macOS]: 'https://docs.cryptopro.ru/cades/plugin/plugin-installation-macos',
//     [PlatformName.windows]: 'https://docs.cryptopro.ru/cades/plugin/plugin-installation-windows',
//     [PlatformName.aurora]: 'https://docs.cryptopro.ru/cades/plugin/plugin-installation-windows',
//     [PlatformName.linux]: 'https://docs.cryptopro.ru/cades/plugin/plugin-installation-unix',
//     default: 'https://docs.cryptopro.ru/cades/plugin/plugin-installation-windows',
// };

export const LINK_PLATFORM_INSTRUCTIONS = {
    [PlatformName.macOS]: 'https://www.cryptopro.ru/products/cades/plugin',
    [PlatformName.windows]: 'https://www.cryptopro.ru/products/cades/plugin',
    [PlatformName.aurora]: 'https://www.cryptopro.ru/products/cades/plugin',
    [PlatformName.linux]: 'https://www.cryptopro.ru/products/cades/plugin',
    default: 'https://www.cryptopro.ru/products/cades/plugin',
};
