import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { stepName } from '~/constants/step-name';
import { TimeoutIdType } from '~/types/common';
import { CadesPluginCertificateData } from '~/types/plugin';
import { StepParams } from '~/types/router';
import { checkCadesStatus } from '~/utils/plugin/check-cades-status';
import {
    CADES_PLUGIN_ECHO_REQUEST_MESSAGE,
    CADES_PLUGIN_LOAD_ERROR_MESSAGE,
} from '~/utils/plugin/constants';
import { fetchAllCertificatesInSystem } from '~/utils/plugin/fetch-all-certificates-in-system';

const RESERVED_DELAY_FETCHING_CERTIFICATES = 3000;

export const useGetCertificatesValues = () => {
    const [isFetchingCertificates, setIsFetchingCertificates] = useState(true);

    const { stepName: enterStep } = useParams<StepParams>();
    const [userCertificates, setUserCertificates] = useState<CadesPluginCertificateData[]>([]);

    const getAllCertificate = useCallback(async () => {
        setIsFetchingCertificates(true);
        try {
            const certificates: CadesPluginCertificateData[] = await fetchAllCertificatesInSystem();

            setUserCertificates(certificates ?? []);
        } catch (err) {
            setUserCertificates([]);
        } finally {
            setIsFetchingCertificates(false);
        }
    }, []);

    useEffect(() => {
        const canPromise = Boolean(window.Promise);
        const { cadesplugin } = window;
        let timerId: TimeoutIdType;

        const getMessageHandler = (event: MessageEvent<unknown>) => {
            if (event.data === CADES_PLUGIN_LOAD_ERROR_MESSAGE) {
                console.error(CADES_PLUGIN_LOAD_ERROR_MESSAGE);
                timerId = setTimeout(() => {
                    setIsFetchingCertificates(false);
                }, RESERVED_DELAY_FETCHING_CERTIFICATES);
                // сообщение об ошибке для браузеров без поддержки Promises API
            }
        };

        if (canPromise) {
            cadesplugin.catch(() => {
                timerId = setTimeout(() => {
                    setIsFetchingCertificates(false);
                }, RESERVED_DELAY_FETCHING_CERTIFICATES);
            });
        } else {
            window.addEventListener('message', getMessageHandler, false);
            window.postMessage(CADES_PLUGIN_ECHO_REQUEST_MESSAGE, '*');
        }

        if (enterStep && enterStep !== stepName.one) {
            checkCadesStatus(getAllCertificate);
        }

        return () => {
            clearTimeout(timerId);
            window.removeEventListener('message', getMessageHandler, false);
        };
    }, [enterStep, getAllCertificate]);

    return { userCertificates, getAllCertificate, isFetchingCertificates };
};
