import dayjs from 'dayjs';

import { DATE_FORMAT } from '~/constants/general';
import { PrivateRoomInputsKeyName } from '~/constants/private-room';
import { UserInfoData } from '~/types/user-info';

export const dateFields = [PrivateRoomInputsKeyName.birthDate] as string[];
export const exceptionsNames = [PrivateRoomInputsKeyName.birthPlace] as string[];
export const getInitialFormValuesCommonBlock = (data: UserInfoData) => {
    const initialFormValues = {} as Record<string, unknown>;

    Object.entries(data).forEach(([key, value]) => {
        if (dateFields.includes(key)) {
            initialFormValues[key] = value?.value ? dayjs(value?.value, DATE_FORMAT) : undefined;
        } else if (exceptionsNames.includes(key)) {
            initialFormValues[key] = value;
        } else if (key === PrivateRoomInputsKeyName.gender) {
            initialFormValues[key] = value?.value === '0' ? '' : value?.value;
        } else {
            initialFormValues[key] = value?.value;
        }
    });

    return initialFormValues;
};
