// константы должны быть аналогичными как и для cadesplugin_api.js - при изменении синхронизировать

export const ERROR_REASONS = {
    EXTENSION: 'extension',
    PLUGIN: 'plugin',
} as const;

export const CADES_PLUGIN_ECHO_REQUEST_MESSAGE = 'cadesplugin_echo_request';

export const CADES_PLUGIN_LOAD_ERROR_MESSAGE = 'cadesplugin_load_error';
