import { LifeStatus } from '~/constants/certificates-table';
import {
    ApplicationDataType,
    CertificateDataType,
    CurrentPageTable,
    PreApplicationDataType,
} from '~/utils/transform-certificates-data/type';

export function isPreApplicationData(
    data: CurrentPageTable,
): data is CurrentPageTable & PreApplicationDataType {
    return (data as PreApplicationDataType).lifeStatus === LifeStatus.preApplication;
}

export const isCertificateData = (
    data: CurrentPageTable,
): data is CurrentPageTable & CertificateDataType =>
    data &&
    (data.lifeStatus === LifeStatus.certificate || data.lifeStatus === LifeStatus.revocation);

export const isApplicationData = (
    data: CurrentPageTable,
): data is CurrentPageTable & ApplicationDataType =>
    data && data.lifeStatus === LifeStatus.application;
