import { CurrentPageTable } from './transform-certificates-data/type';
import { getTitleWithSerialNumber } from './get-title-with-serial-number';

export const transformTitleToCertificateTable = (
    titleInAllTitles: string,
    currentPage: CurrentPageTable[],
) => {
    const item = currentPage.find((certificate) => certificate.title.includes(titleInAllTitles));

    return getTitleWithSerialNumber(titleInAllTitles, item);
};
