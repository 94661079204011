import { AlertMessage } from '~/constants/alert-message';
import { API } from '~/rtk-queries/constants/api';
import { ApiGroupNames } from '~/rtk-queries/constants/api-group-names';
import { EndpointNames } from '~/rtk-queries/constants/endpoint-names';
import { transformErrorWithAlert } from '~/rtk-queries/helpers/transform-error-with-alert';
import { interceptor } from '~/rtk-queries/interceptor';
import {
    CheckSignatureDocumentRequest,
    CheckSignatureDocumentResponse,
    CheckVerifyCertificateRequest,
    CheckVerifyCertificateResponse,
    GetSvsPolicy,
} from '~/rtk-queries/types/certificate-verification';
import { CommonResponse } from '~/types/common';

export const certificateVerification = interceptor.injectEndpoints({
    endpoints: (builder) => ({
        getSvsPolicy: builder.query<CommonResponse<GetSvsPolicy>, void>({
            query: (body) => ({
                url: API.getSvsPolicy,
                method: 'GET',
                apiGroupName: ApiGroupNames.CERTIFICATE_VERIFICATION,
                name: EndpointNames.GET_SVS_POLICY,
                body,
            }),
            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),
        checkVerifyCertificate: builder.mutation<
            CommonResponse<CheckVerifyCertificateResponse>,
            CheckVerifyCertificateRequest
        >({
            query: (data) => ({
                url: `${API.checkVerifyCertificate}`,
                method: 'POST',
                apiGroupName: ApiGroupNames.CERTIFICATE_VERIFICATION,
                name: EndpointNames.CHECK_VERIFY_CERTIFICATE,
                body: data,
            }),
            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),
        checkSignatureDocument: builder.mutation<
            CommonResponse<CheckSignatureDocumentResponse>,
            CheckSignatureDocumentRequest
        >({
            query: (data) => ({
                url: `${API.checkSignatureDocuments}`,
                method: 'POST',
                apiGroupName: ApiGroupNames.CERTIFICATE_VERIFICATION,
                name: EndpointNames.CHECK_SIGNATURE_DOCUMENTS,
                body: data,
            }),
            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),
    }),
});

export const {
    useGetSvsPolicyQuery,
    useCheckVerifyCertificateMutation,
    useCheckSignatureDocumentMutation,
} = certificateVerification;
