export enum CertificatesColumnVariant {
    title = 'Сертификат',
    currentStatus = 'Текущий статус',
    lastEditDate = 'Изменение статуса',
    actionsVariant = 'Действия',
    documents = 'Документы',
}

export enum CertificatesColumnsName {
    title = 'title',
    statusTag = 'status',
    lastEditDate = 'lastEditDate',
    actionsVariant = 'actionsVariant',
    documents = 'documents',
}

export enum LifeStatus {
    preApplication = 0,
    application = 1,
    certificate = 2,
    revocation = 3,
}

export enum EnrichmentStatusOut {
    Pending = 'Pending',
    Success = 'Success',
    Error = 'Error',
    Waiting = 'Waiting',
}

export const EnrichmentStatusOutText = {
    [EnrichmentStatusOut.Pending]: 'Выполняется',
    [EnrichmentStatusOut.Success]: 'Успешно',
    [EnrichmentStatusOut.Error]: 'Ошибка проверки',
    [EnrichmentStatusOut.Waiting]: 'Ожидается результат проверки',
};

export const CERTIFICATES_COLUMNS = {
    [CertificatesColumnsName.title]: CertificatesColumnVariant.title,
    [CertificatesColumnsName.statusTag]: CertificatesColumnVariant.currentStatus,
    [CertificatesColumnsName.lastEditDate]: CertificatesColumnVariant.lastEditDate,
    [CertificatesColumnsName.actionsVariant]: CertificatesColumnVariant.actionsVariant,
    [CertificatesColumnsName.documents]: CertificatesColumnVariant.documents,
};
