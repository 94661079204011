import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCheckCadesExtension } from './use-check-cades-extension';
import { useFirstRender } from './use-first-render';

import { ROUTES } from '~/constants/routes';
import { stepName } from '~/constants/step-name';
import { useLoaderContext } from '~/contexts/hooks';
import { StepParams } from '~/types/router';
import { FrontConfigType } from '~/utils/get-front-config/type';

export const useEnterPage = (frontConfig?: FrontConfigType) => {
    const { stepName: enterStep } = useParams<StepParams>();
    const { stopLoading } = useLoaderContext();
    const navigate = useNavigate();
    const isMount = useFirstRender();
    const [isOpenModalAccessConfirmation, setIsOpenModalAccessConfirmation] = useState(false);

    const { cadesStatus, browserLink, platformLink } = useCheckCadesExtension(frontConfig);

    const linkInstall =
        (enterStep === stepName.two && browserLink) ||
        (enterStep === stepName.three && platformLink) ||
        undefined;

    const onClickElectronicSignature = useCallback(async () => {
        if (!cadesStatus) {
            navigate(`${ROUTES.stepsRootPage}/${stepName.two}`);

            return;
        }
        navigate(`${ROUTES.stepsRootPage}/${stepName.four}`);
    }, [cadesStatus, navigate]);

    const onClickOtherLogin = useCallback(() => {
        navigate(`${ROUTES.stepsRootPage}/${stepName.one}`);
    }, [navigate]);

    const onClickInstallExtension = useCallback(() => {
        navigate(`${ROUTES.stepsRootPage}/${stepName.three}`);
    }, [navigate]);

    const onClickDownloadPlugin = useCallback(() => {
        navigate(`${ROUTES.stepsRootPage}/${stepName.four}`);
    }, [navigate]);

    const onClickCheckElectronicSignature = useCallback(() => {
        stopLoading();
        setIsOpenModalAccessConfirmation(true);
    }, [stopLoading]);

    // дополнительная проверка при перезагрузке страницы со степом отличным от первого
    useEffect(() => {
        if (!isMount || enterStep === stepName.one) return;

        if (cadesStatus) {
            navigate(`${ROUTES.stepsRootPage}/${stepName.four}`);
        } else {
            navigate(`${ROUTES.stepsRootPage}/${stepName.two}`);
        }
    }, [cadesStatus, enterStep, isMount, navigate]);

    return {
        cadesStatus,
        linkInstall,
        onClickElectronicSignature,
        onClickOtherLogin,
        onClickInstallExtension,
        onClickDownloadPlugin,
        onClickCheckElectronicSignature,
        isOpenModalAccessConfirmation,
        setIsOpenModalAccessConfirmation,
    };
};
