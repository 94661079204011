import { Button, Typography } from 'antd';

import styles from './select-certificate.module.css';

import { ButtonType } from '~/constants/button';
import { CadesPluginCertificateData } from '~/types/plugin';
import { convertSubjectToDataCertificate } from '~/utils/conver-subject-to-data-certificate';

type SelectCertificateProps = {
    thumbprint: string;
    certificate: CadesPluginCertificateData;
    validToDate: string;
    authorizationByCertificateHandler?: (id: string) => Promise<void>;
};

export const SelectCertificate = ({
    thumbprint,
    certificate,
    validToDate,
    authorizationByCertificateHandler,
}: SelectCertificateProps) => {
    const { email, surname, name, fullName, issueBy, dateTo } = convertSubjectToDataCertificate({
        certificate,
        validToDate,
    });

    const onClick = () => {
        if (authorizationByCertificateHandler) {
            authorizationByCertificateHandler(thumbprint);
        }
    };

    return (
        <Button
            htmlType={ButtonType.button}
            onClick={onClick}
            className={styles.selectCertificateButton}
        >
            <Typography.Title className={styles.titleCertificate} style={{ fontSize: '16px' }}>
                {fullName || `${surname} ${name}`}
            </Typography.Title>
            {/* // TODO временно закоменчено */}
            {/* <Tag color='green'>{statusText(true)}</Tag> */}
            {email && <Typography.Text className={styles.textLeft}>Email: {email}</Typography.Text>}
            <Typography.Text className={styles.selectCertificateText}>
                Кем выдан: {issueBy}
            </Typography.Text>
            <Typography.Text className={styles.textLeft}>Действителен до: {dateTo}</Typography.Text>
        </Button>
    );
};
