import { API } from '../constants/api';
import { transformErrorWithAlert } from '../helpers/transform-error-with-alert';
import { interceptor } from '../interceptor';

import { AlertMessage } from '~/constants/alert-message';
import { ApiGroupNames } from '~/rtk-queries/constants/api-group-names';
import { EndpointNames } from '~/rtk-queries/constants/endpoint-names';
import { Tags } from '~/rtk-queries/constants/tags';
import {
    CountriesResponse,
    UserInfoRequest,
    UserInfoResponse,
    UserNameResponse,
} from '~/rtk-queries/types/private-room';
import { CommonResponse } from '~/types/common';

export const privateRoomInfo = interceptor
    .enhanceEndpoints({
        addTagTypes: [Tags.USER_NAME],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getUserInfo: builder.mutation<CommonResponse<UserInfoResponse>, UserInfoRequest>({
                query: ({ folderId, templateId }) => ({
                    url: API.userInfo,
                    method: 'GET',
                    apiGroupName: ApiGroupNames.privateRoom,
                    name: EndpointNames.GET_USER_INFO,
                    params: {
                        folderId,
                        templateId,
                    },
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
            }),

            getRegionInfo: builder.query<CommonResponse<CountriesResponse>, void>({
                query: () => ({
                    url: API.countries,
                    method: 'GET',
                    apiGroupName: ApiGroupNames.privateRoom,
                    name: EndpointNames.GET_REGION_INFO,
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
            }),

            getUserName: builder.query<CommonResponse<UserNameResponse>, void>({
                query: () => ({
                    url: API.userName,
                    method: 'GET',
                    apiGroupName: ApiGroupNames.privateRoom,
                    name: EndpointNames.GET_USER_NAME,
                }),
                providesTags: [Tags.USER_NAME],
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
            }),
        }),
    });

export const { useGetUserInfoMutation, useGetUserNameQuery, useGetRegionInfoQuery } =
    privateRoomInfo;
