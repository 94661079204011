export const LOCAL_STORAGE = {
    sessionId: 'session_id',
    refreshToken: 'refresh_token',
    filtersForCertificates: 'filters_for_certificates',
    filtersForArchives: 'filters_for_archives',
    isEsiaLogin: 'isEsiaLogin',
    isUserAuthenticated: 'is_user_auth',
    thumbprint: 'thumbprint',
    draftData: 'draft_data',
};
