import { title } from 'process';
import { getModalErrorText } from '../get-modal-error-text';
import { uuidGenerator } from '../uuid-generator';

import { ModalTextVariant } from '~/constants/modal';
import { GENERATE_KEYS_DATA, PSB_CONTAINER_NAME } from '~/constants/plugin';
import { Template } from '~/rtk-queries/types/template';
import { CSP, HashAlgorithm } from '~/types/plugin';

// Утилита для генерации ключей на основе данных сертификата
export const generateKeysUtil = async (templateData: Template, distinguishedName: string) => {
    const { enrolljs } = window;
    const containerName = uuidGenerator(PSB_CONTAINER_NAME);
    const {
        showProtectUI,
        reuseKey,
        limitToEncipherment,
        signRequest,
        signByThumbprint,
        addTemplate,
        addIdentificationKind,
        identificationKind,
    } = GENERATE_KEYS_DATA;

    try {
        const cspList: CSP[] = await enrolljs.getCspListFromSystem();

        if (cspList && typeof cspList === 'object') {
            const currentProvider = await cspList.find(
                (csp) => templateData?.provType.find((type) => csp.type === type),
            );

            const newKeySize = await enrolljs.getKeyLength(
                currentProvider?.name,
                currentProvider?.keySpec,
                true,
            );

            const hash: HashAlgorithm[] = await enrolljs.getHashAlgorithmListFromCsp(
                currentProvider?.name,
            );

            // Создание запроса на сертификат с использованием данных сертификата и статичных данных
            const { rawCertRequest } = await enrolljs.createCertRequest(
                currentProvider?.name,
                currentProvider?.type,
                templateData?.keySpec,
                newKeySize,
                templateData?.isExportable,
                showProtectUI,
                templateData?.isMachine,
                containerName,
                reuseKey,
                limitToEncipherment,
                distinguishedName,
                signRequest,
                signByThumbprint,
                hash[0].oid,
                addTemplate,
                templateData?.oid,
                templateData?.majorVersion,
                templateData?.minorVersion,
                addIdentificationKind,
                identificationKind,
            );

            return { text: rawCertRequest, isError: false, title: '' };
        }

        return { text: '', isError: true, title: ModalTextVariant.cancel };
    } catch (e) {
        return getModalErrorText(e);
    }
};
