import dayjs from 'dayjs';

import { DATE_FORMAT } from '~/constants/general';
import { Doc, PrivateRoomInputsKeyName } from '~/constants/private-room';
import { UserInfoDataDocuments } from '~/utils/convert-docs-to-arr/type';

export type CommonObj = Record<string, unknown>;
export const dateFields = [
    PrivateRoomInputsKeyName.docExpiryDate,
    PrivateRoomInputsKeyName.docIssueDate,
    PrivateRoomInputsKeyName.docIssuedBy,
    PrivateRoomInputsKeyName.docSeries,
    PrivateRoomInputsKeyName.docNumber,
    PrivateRoomInputsKeyName.docIssueId,
] as string[];

export const getInitialFormValuesDocumentsBlock = (
    documents: UserInfoDataDocuments[] | null,
    type: Doc,
) => {
    const initialObj = {} as CommonObj;

    const matchingDocument = documents?.find((doc) => doc.type === type);

    if (matchingDocument) {
        Object.entries(matchingDocument).forEach(([key, value]) => {
            if (
                key === PrivateRoomInputsKeyName.docExpiryDate ||
                key === PrivateRoomInputsKeyName.docIssueDate
            ) {
                initialObj[key] = value ? dayjs(value, DATE_FORMAT) : null;
            } else {
                initialObj[key] = value;
            }
        });
    } else {
        // если документа с таким типом нет, заполняем initialObj null-ами
        dateFields.forEach((field) => {
            initialObj[field] = null;
        });
    }

    return initialObj;
};
