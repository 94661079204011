import { FormInstance, FormRule } from 'antd';
import { CustomDataPicker } from 'shared/form-components/custom-data-picker';
import { CustomSelect } from 'shared/form-components/custom-select';

import styles from './main-form-block.module.css';

import { FormBlockLabel } from '~/components/application-tab/constants/general';
import { PRIVATE_ROOM, PRIVATE_ROOM_SUBTITLE } from '~/constants/private-room';
import { CountriesResponse, UserInfoResponse } from '~/rtk-queries/types/private-room';
import { CustomMaskInput } from '~/shared/form-components/custom-mask-input';
import { convertUserDataMainToArr } from '~/utils/convert-user-data-main-to-arr';
import { optionCountry } from '~/utils/get-country-data';

type MainFormBlockProps<T> = {
    form: FormInstance<T>;
    countriesData: CountriesResponse;
    data?: UserInfoResponse;
};

export const MainFormBlock = <T,>({ data, form, countriesData }: MainFormBlockProps<T>) => {
    const project = (
        name: string[],
        label: string,
        isDisabled: boolean,
        rules?: FormRule[],
        mask?: string,
    ) => {
        const currentValue = form.getFieldValue(name[0]);

        switch (label) {
            case FormBlockLabel.citizenshipId:
                return (
                    <CustomSelect
                        label={label}
                        keyName={name[0]}
                        key={label}
                        disabled={isDisabled}
                        options={optionCountry(countriesData.countries)}
                        rules={rules}
                    />
                );
            case FormBlockLabel.gender:
                return (
                    <CustomSelect
                        label={label}
                        keyName={name[0]}
                        key={label}
                        disabled={isDisabled}
                        options={
                            PRIVATE_ROOM.inputs.personalData.find(
                                ({ keyName }) => keyName === name[0],
                            )?.options
                        }
                        rules={rules}
                    />
                );
            case FormBlockLabel.birthDate:
                return (
                    <CustomDataPicker
                        label={label}
                        keyName={name[0]}
                        key={label}
                        disabled={isDisabled}
                        rules={rules}
                    />
                );
            case FormBlockLabel.mobile:
                return (
                    <CustomMaskInput
                        label={label}
                        keyName={name[0]}
                        key={label}
                        rules={rules}
                        disabled={isDisabled}
                        mask={mask}
                    />
                );
            default:
                return (
                    <CustomMaskInput
                        label={label}
                        keyName={name[0]}
                        key={label}
                        rules={rules}
                        disabled={isDisabled}
                        mask={mask}
                        currentValue={currentValue}
                    />
                );
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.applicationTabSubtitle}>{PRIVATE_ROOM_SUBTITLE.main}</div>
            <div className={styles.applicationTabBlock}>
                {convertUserDataMainToArr(data?.userInfo?.main).map(
                    ({ name, label, isDisabled, rules, mask }) =>
                        project(name, label, isDisabled, rules, mask),
                )}
            </div>
        </div>
    );
};
