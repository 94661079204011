import { TabsProps } from 'antd';

import { PrivateRoomTabsName } from './tabs-name';

import { ApplicationTab } from '~/components/application-tab';
import { Archive } from '~/components/archive';
import { CertificatesTable } from '~/components/certificates-table';
import { CheckAndSignCertificate } from '~/components/check-and-sign-certificate';
import { PRIVATE_ROOM } from '~/constants/private-room';
import { MchdTab } from '~/components/mchd-tab/mchd-tab';

export const items: TabsProps['items'] = [
    {
        key: PrivateRoomTabsName.applicationTab,
        label: PRIVATE_ROOM.tabsLabel[PrivateRoomTabsName.applicationTab],
        children: <ApplicationTab />,
    },
    {
        key: PrivateRoomTabsName.certificateTab,
        label: PRIVATE_ROOM.tabsLabel[PrivateRoomTabsName.certificateTab],
        children: <CertificatesTable />,
    },
    {
        key: PrivateRoomTabsName.checkCertificateTab,
        label: PRIVATE_ROOM.tabsLabel[PrivateRoomTabsName.checkCertificateTab],
        children: <CheckAndSignCertificate />,
    },
    {
        key: PrivateRoomTabsName.mchdTab,
        label: PRIVATE_ROOM.tabsLabel[PrivateRoomTabsName.mchdTab],
        children: <MchdTab />,
    },
    {
        key: PrivateRoomTabsName.archiveTab,
        label: PRIVATE_ROOM.tabsLabel[PrivateRoomTabsName.archiveTab],
        children: <Archive />,
    },
];
