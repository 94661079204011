import { API } from '../constants/api';
import { transformErrorWithAlert } from '../helpers/transform-error-with-alert';
import { interceptor } from '../interceptor';

import { AlertMessage } from '~/constants/alert-message';
import { CommonResponse } from '~/types/common';
import { getDisplayNameByOID } from '~/utils/get-display-name-by-oid/get-display-name-by-oid';
import { NamePolicyType } from '~/utils/get-display-name-by-oid/type';

export const namePolicy = interceptor.injectEndpoints({
    endpoints: (builder) => ({
        getNamePolicy: builder.query<Record<string, string>, void>({
            query: () => ({
                url: API.namePolicy,
                method: 'GET',
            }),
            transformResponse: ({ namePolicy }: CommonResponse<NamePolicyType>) =>
                getDisplayNameByOID(namePolicy.nameAttributes),

            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),
    }),
});

export const { useLazyGetNamePolicyQuery } = namePolicy;
