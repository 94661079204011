import { FormInstance } from 'antd';
import { CustomCheckboxGroup } from 'shared/form-components/custom-checkbox-group';

import { CheckCertificateFields, FormTypes } from '../types/form-types';

import styles from './additional-checks.module.css';

import { returnOptions } from '~/components/check-and-sign-certificate/content/check-certificate/helpers/return-options';
import { useFirstRender } from '~/hooks/use-first-render';
import { CertificateVerifier } from '~/rtk-queries/types/certificate-verification';

type AdditionalChecksProps = {
    form: FormInstance<FormTypes>;
    certificateVerifiers: CertificateVerifier[];
    disabled?: boolean;
};

export const AdditionalChecks = ({
    form,
    certificateVerifiers,
    disabled,
}: AdditionalChecksProps) => {
    const { options, defaultValue } = returnOptions(certificateVerifiers);
    const isFirstRender = useFirstRender();

    if (isFirstRender) {
        form.setFieldValue(CheckCertificateFields.certVerifiersPluginsIds, defaultValue);
    }

    return (
        <CustomCheckboxGroup
            label='Дополнительные проверки'
            defaultValue={defaultValue}
            name={CheckCertificateFields.certVerifiersPluginsIds}
            options={options}
            className={styles.checkBoxGroup}
            disabled={disabled}
        />
    );
};
