import { FC, PropsWithChildren, ReactNode, useMemo, useState } from 'react';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Select, SelectProps, Spin } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import classnames from 'classnames';

import { CustomFormItemProps } from '../../types';
import { CustomFormItem } from '../custom-form-item';

import styles from './form-select.module.css';

export const FormSelect: FC<
    PropsWithChildren<
        CustomFormItemProps<{ hasEmptyOption?: boolean }> & { selectProps: SelectProps }
    >
> = ({ selectProps, children, hasEmptyOption = true, arrowDisabled, label, ...restProps }) => {
    const {
        className,
        filterOption,
        options,
        onDropdownVisibleChange,
        disabled,
        loading,
        mode,
        ...restSelectProps
    } = selectProps;

    const [isRotate, setIsRotate] = useState(false);

    const handleDropdownVisibleChange = (open: boolean) => {
        setIsRotate(open);
        onDropdownVisibleChange?.(open);
    };

    const defaultFilterOption = (input: ReactNode, option?: DefaultOptionType) =>
        (typeof option?.label === 'string' ? option.label : '')
            .toLowerCase()
            .includes(String(input).toLowerCase());

    const optionsWithEmptyOption = useMemo(
        () =>
            hasEmptyOption && !mode && options ? [{ label: '', value: '' }, ...options] : options,
        [hasEmptyOption, mode, options],
    );

    return (
        <CustomFormItem {...restProps} label={label}>
            <Select
                suffixIcon={
                    loading ? (
                        <Spin
                            indicator={<LoadingOutlined className={styles.icon} spin={true} />}
                            size='small'
                        />
                    ) : (
                        !disabled && (
                            <DownOutlined
                                onClick={() => setIsRotate(!isRotate)}
                                style={{ pointerEvents: arrowDisabled ? 'none' : 'auto' }}
                                className={classnames(styles.icon, {
                                    [styles.rotate]: isRotate,
                                })}
                            />
                        )
                    )
                }
                open={isRotate}
                loading={loading}
                className={classnames(styles.selectAlign, className)}
                disabled={disabled}
                filterOption={filterOption || defaultFilterOption}
                mode={mode}
                options={optionsWithEmptyOption}
                onDropdownVisibleChange={handleDropdownVisibleChange}
                {...restSelectProps}
            >
                {children}
            </Select>
        </CustomFormItem>
    );
};
