import { useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { Spin } from 'antd';

import styles from './esia-redirect.module.css';

import { ESIA_REDIRECT } from '~/constants/esia-redirect';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { ROUTES } from '~/constants/routes';
import { useAppSelector } from '~/hooks';
import { usePostLoginByEsiaMutation } from '~/rtk-queries/endpoints/authentication';
import { accessTokenSelector } from '~/store/auth/auth-slice';
import { getLocalStorageItem } from '~/utils/local-storage';

export const EsiaRedirect = () => {
    const [params] = useSearchParams();
    const code = params.get('code') || '';
    const state = params.get('state') || '';
    const sessionId = Number(getLocalStorageItem(LOCAL_STORAGE.sessionId));
    const accessToken = useAppSelector(accessTokenSelector);

    const [postLoginByEsia] = usePostLoginByEsiaMutation();

    useEffect(() => {
        if (code && state && sessionId) {
            postLoginByEsia({ sessionId, code, state });
        }
    }, [postLoginByEsia, code, state, sessionId]);

    if (accessToken) {
        return <Navigate to={ROUTES.privateRoom} replace={true} />;
    }

    if (!code) {
        return <Navigate to={ROUTES.startPage} replace={true} />;
    }

    return (
        <Spin fullscreen={true} tip={ESIA_REDIRECT.tooltipText}>
            <div className={styles.esiaRedirectContainer} />
        </Spin>
    );
};
