import { CSSProperties } from 'react';
import { FilePdfOutlined, FileWordOutlined, PaperClipOutlined } from '@ant-design/icons';

import { FileExtensionsType } from '~/shared/file-extenion-icon/type';

type FileExtensionIconProps = {
    type: FileExtensionsType | string;
    className?: string;
    style?: CSSProperties;
};

export const FileExtensionIcon = ({ type, ...restProps }: FileExtensionIconProps) => {
    switch (type) {
        case 'docx': {
            return <FileWordOutlined {...restProps} />;
        }
        case 'pdf': {
            return <FilePdfOutlined {...restProps} />;
        }
        default:
            return <PaperClipOutlined {...restProps} />;
    }
};
