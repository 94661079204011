import { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';
import { Modal, ModalProps } from 'antd';
import classnames from 'classnames';

import styles from './custom-modal.module.css';

type CustomModalType = ModalProps & {
    isClose: boolean;
    setOpenModal: Dispatch<SetStateAction<boolean>>;
};

export const CustomModal: FC<PropsWithChildren<CustomModalType>> = ({
    setOpenModal,
    children,
    isClose,
    className: classes,
    ...restModalProps
}) => {
    const handleOpenModal = () => setOpenModal(false);

    return (
        <Modal
            centered={true}
            destroyOnClose={true}
            onCancel={handleOpenModal}
            title={false}
            className={classnames(styles.modal, classes)}
            closeIcon={isClose}
            {...restModalProps}
        >
            {children}
        </Modal>
    );
};
