import { useCallback, useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';

import { useGetAccessToken } from './use-get-access-token';

import { LOCAL_STORAGE } from '~/constants/local-storage';
import { AuthErrorMessage, ModalTextVariant } from '~/constants/modal';
import {
    useAuthByCertMutation,
    useEnsureSessionQuery,
} from '~/rtk-queries/endpoints/authentication';
import { LoginByCertificateResponse } from '~/rtk-queries/types/auth';
import { CommonResponse } from '~/types/common';
import { getLocalStorageItem } from '~/utils/local-storage';
import { signAuthRequest } from '~/utils/plugin/sign-auth-request';

export const useCertificateAuthorization = () => {
    const [isOpenAnswerModal, setIsOpenAnswerModal] = useState(false);
    const [answerForModal, setAnswerForModal] = useState({ text: '', title: '', isError: false });
    const [isLoading, setIsLoading] = useState(true);

    useGetAccessToken(setIsLoading);

    const refreshToken = getLocalStorageItem(LOCAL_STORAGE.refreshToken);

    const [authByCert, { isError: isErrorInAuthByCert, isLoading: isLoadingInAuthByCert, error }] =
        useAuthByCertMutation();

    const { data: sessionData } = useEnsureSessionQuery(refreshToken ? skipToken : {});

    const authorizationByCertificateHandler = useCallback(
        async (thumbprint: string) => {
            try {
                const signedContent = await signAuthRequest(
                    sessionData?.base64KeyString || '',
                    thumbprint,
                );

                if (signedContent.isError) {
                    setAnswerForModal({
                        text: signedContent.text,
                        title: signedContent.title,
                        isError: true,
                    });
                    setIsOpenAnswerModal(true);
                } else {
                    authByCert({
                        sessionId: sessionData?.sessionId || 0,
                        signedContent: signedContent.text,
                        thumbprint,
                    });
                    localStorage.setItem(LOCAL_STORAGE.thumbprint, thumbprint);
                }
            } catch (err: unknown) {
                console.log(err);
            }
        },
        [authByCert, sessionData?.base64KeyString, sessionData?.sessionId],
    );

    useEffect(() => {
        if (isErrorInAuthByCert && error) {
            const { message } = error as CommonResponse<LoginByCertificateResponse>;
            const isNotValid = message?.toLowerCase().includes(AuthErrorMessage.revoked);
            const isNotPKI = message?.toLowerCase().includes(AuthErrorMessage.notPKI);
            const text = {
                isNotValid: isNotValid && ModalTextVariant.certificateIsNotValid,
                isNotPKI: isNotPKI && ModalTextVariant.certificateDidNotFind,
                other: !isNotPKI && !isNotValid && ModalTextVariant.tryAgain,
            };

            const title = {
                isNotValid: isNotValid && ModalTextVariant.certificateIsNotValidTitle,
                isNotPKI: isNotPKI && ModalTextVariant.certificateDidNotFindTitle,
                other: !isNotPKI && !isNotValid && ModalTextVariant.error,
            };

            const modalText = text.isNotValid || text.isNotPKI || text.other || '';
            const modalTitle = title.isNotValid || title.isNotPKI || title.other || '';

            setAnswerForModal({ text: modalText, title: modalTitle, isError: true });
            setIsOpenAnswerModal(true);
        }
    }, [error, isErrorInAuthByCert]);

    return {
        isLoading,
        authorizationByCertificateHandler,
        isLoadingInAuthByCert,
        isErrorInAuthByCert,
        isOpenAnswerModal,
        setIsOpenAnswerModal,
        answerForModal,
        setAnswerForModal,
    };
};
