import { useEffect, useState } from 'react';
import { FrontConfigType } from '~/utils/get-front-config/type';

import { checkCadesExtension } from '~/utils/plugin/check-cades-extension';

export const useCheckCadesExtension = (frontConfig?: FrontConfigType) => {
    const [cadesStatus, setCadesStatus] = useState(true);
    const [browserLink, setBrowserLink] = useState('');
    const [platformLink, setPlatformLink] = useState('');

    useEffect(() => {
        checkCadesExtension(setCadesStatus, setBrowserLink, setPlatformLink, frontConfig);
    }, [frontConfig]);

    return { cadesStatus, browserLink, platformLink };
};
