import { FormTypes } from '~/components/application-tab/types/form-types';
import { Doc } from '~/constants/private-room';
import { Nullable } from '~/types/common';
import { convertDateToString } from '~/utils/convert-date-to-string';
import { removeCharactersFromString } from '~/utils/remove-characters-from-string';

export const preparationToSendForServer = (formValues: Nullable<Partial<FormTypes>>) => {
    const {
        type,
        series,
        number,
        issueDate,
        issuedBy,
        issueId,
        expiryDate,
        birthDate,
        mobile,
        gender,
        citizenshipId,
        countryId,
        certificateTemplate,
        birthPlace,
        ...rest
    } = formValues;

    const document = {
        type,
        series,
        number,
        issueDate: convertDateToString({ date: issueDate }),
        issuedBy,
        issueId,
        ...(type !== Doc.RfPassport && { expiryDate: convertDateToString({ date: expiryDate }) }),
    };

    return {
        ...rest,
        mobile: removeCharactersFromString(mobile!),
        birthDate: convertDateToString({ date: birthDate }),
        gender: Number(gender) || 0,
        citizenshipId: Number(citizenshipId) || 0,
        countryId: Number(countryId) || 0,
        birthPlace: birthPlace ?? '',
        document,
    };
};
