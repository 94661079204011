import { Template } from '~/rtk-queries/types/template';
import { OptionType } from '~/types/common';

export const convertTemplatesToOptions = (templates?: Template[]) => {
    const convertArr = [] as OptionType[];

    templates?.reduce(
        (result, template) =>
            convertArr.push({
                value: `${template.folderId}-${template.id}-${template.description}`,
                label: template.displayName,
            }),
        {},
    );

    return convertArr;
};
