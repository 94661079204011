import { CertificateData, CurrentPageTable } from './transform-certificates-data/type';

import { LifeStatus } from '~/constants/certificates-table';

export const getTitleWithSerialNumber = (
    title: string,
    item?: CurrentPageTable | CertificateData,
) =>
    item && item.lifeStatus === LifeStatus.certificate
        ? `${title} Серийный номер: ${item.serialNumber}`
        : title;
