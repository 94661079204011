import { PrivateRoomInputsKeyName } from '~/constants/private-room';

export const RESET_ARRAY = [
    PrivateRoomInputsKeyName.docSeries,
    PrivateRoomInputsKeyName.docNumber,
    PrivateRoomInputsKeyName.docIssueDate,
    PrivateRoomInputsKeyName.docIssueId,
    PrivateRoomInputsKeyName.docIssuedBy,
    PrivateRoomInputsKeyName.docExpiryDate,
];

export enum FormBlockLabel {
    citizenshipId = 'Гражданство',
    gender = 'Пол',
    birthDate = 'День рождения',
    mobile = 'Мобильный',
    region = 'Страна/регион',
}

export enum FormPlaceholders {
    CertificateTemplateSelect = 'Назначение сертификата',
}

export const NotificationTextPartOne = {
    text: 'При обнаружении ошибок в данных измените их в',
    linkText: 'Госуслугах',
    link: 'https://www.gosuslugi.ru/',
};

export const NotificationTextPartTwo = 'и повторно авторизуйтесь через сервис Госуслуг';
