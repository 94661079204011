import { SignedDocumentType } from '~/types/common';

export const downloadSignature = ({ base64File, fileName }: SignedDocumentType) => {
    const blob = new Blob([base64File], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.style.display = 'none';
    a.href = url;
    a.download = `${fileName}.sig`;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
};
