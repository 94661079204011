import { getModalErrorText } from '../get-modal-error-text';

// генерация подписи для авторизации, отдает сгенерированную подпись по base64Data и ID сертификата
export const signAuthRequest = async (
    base64Data: string,
    thumbprint: string,
    isAttached = false,
) => {
    const { enrolljs } = window;

    try {
        const signedContent: string = await enrolljs.signData(
            base64Data,
            thumbprint,
            isAttached, // Вид подписи: true - отсоединенная, false -присоединенная. По умолчанию - false(присоединенная подпись).
        );

        // возвращаем необходимые для авторизации по сертификату данные
        return { text: signedContent, isError: false, title: '' };
    } catch (e) {
        return getModalErrorText(e);
    }
};
