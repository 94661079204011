import { PluginError } from './plugin';

import { ErrorText } from '~/types/errors';

export enum ModalType {
    revoke = 'revoke',
    viewAnswer = 'viewAnswer',
    generateKeys = 'generateKeys',
}
export const MODAL_TYPE = {
    revoke: ModalType.revoke,
    viewAnswer: ModalType.viewAnswer,
    generateKeys: ModalType.generateKeys,
};

export enum AuthErrorMessage {
    revoked = 'revoked',
    notPKI = "didn't find",
}

export enum ModalTextVariant {
    generateKeys = 'При подтверждении запроса на сертификат на вашем носителе создаётся контейнер с электронными ключами. Возьмите ваш носитель с собой в Банк для установки сертификата Оператором. Или Вы можете установить его самостоятельно в личном кабинете после проверки и подтверждения запроса Оператором Банка.',
    installOk = 'Сертификат успешно установлен',
    isNotInPersonalStorage = 'Сертификат не установлен в личное хранилище',
    keyUndefined = 'Контейнер с ключами не обнаружен',
    keyOrContainerUndefined = 'Контейнер не обнаружен либо сертификат не установлен в контейнер',
    incorrectParameter = 'Параметр задан неверно',
    addContainer = 'Вставьте носитель с контейнером',
    createRequest = 'Новый сертификат',
    createDraft = 'Черновик',
    certificateIsNotValid = 'Сертификат отозван. Авторизуйтесь по другому сертификату',
    certificateDidNotFind = 'Вход по данному сертификату не возможен. Выберите другой сертификат либо авторизуйтесь через Госуслуги.',
    tryAgain = 'Попробуйте еще раз',
    error = 'Ошибка',
    cancel = 'Действие было отменено пользователем',
    dataReset = 'Данные будут сброшены. Вы уверены, что хотите перейти?',
    needExtention = 'Включите расширение браузера CryptoPro Extension for CAdES Browser Plug-in и перезагрузите страницу',
    answer = 'Результаты проверок',
    removeDraft = 'Черновик был успешно удалён',
    errorTemplateMessage = 'Удостоверяющий центр больше не выпускает сертификат с таким назначением. Пожалуйста, удалите черновик и создайте новую заявку на сертификат.',
    attention = 'Внимание!',
    needExtentionTitle = 'Отсутствует расширение браузера',
    certificateIsNotValidTitle = 'Сертификат отозван',
    certificateDidNotFindTitle = 'Неизвестный сертификат',
}

export const ERROR_TEXTS: ErrorText = {
    [PluginError.cancel]: ModalTextVariant.cancel,
    [PluginError.cancelInInstall]: ModalTextVariant.cancel,
    [PluginError.isNotInPersonalStorage]: ModalTextVariant.isNotInPersonalStorage,
    [PluginError.keyUndefined]: ModalTextVariant.keyUndefined,
    [PluginError.incorrectParameter]: ModalTextVariant.incorrectParameter,
    [PluginError.lostConnection]: ModalTextVariant.needExtention,
    [PluginError.containerNotFound]: ModalTextVariant.keyUndefined,
    [PluginError.internalError]: ModalTextVariant.keyUndefined,
    default: ModalTextVariant.addContainer,
};
