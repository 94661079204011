import { rulesInput } from './rules-input';

import { Doc } from '~/constants/private-room';

export const generateRulesForDocType = (regexArray: string[], required: boolean = true) => ({
    [Doc.RfPassport]: rulesInput({ required, regex: regexArray[0] }),
    [Doc.FidDoc]: rulesInput({ required, regex: regexArray[1] }),
    [Doc.TempRes]: rulesInput({ required, regex: regexArray[2] }),
    [Doc.ResPermit]: rulesInput({ required, regex: regexArray[3] }),
});
