import { useEffect } from 'react';
import { FormInstance } from 'antd';
import { CustomDataPicker } from 'shared/form-components/custom-data-picker';
import { CustomSelect } from 'shared/form-components/custom-select';

import { RESET_ARRAY } from '../constants/general';
import { getRulesForDocuments } from '../helpers/get-rules-for-documents';

import styles from './documents-form-block.module.css';

import { VALIDATION_RULES } from '~/constants/general';
import {
    Doc,
    docNumberLabel,
    PRIVATE_ROOM,
    PRIVATE_ROOM_SUBTITLE,
    PrivateRoomInput,
    PrivateRoomInputsKeyName,
} from '~/constants/private-room';
import { UserInfoResponse } from '~/rtk-queries/types/private-room';
import { CustomMaskInput } from '~/shared/form-components/custom-mask-input';
import { ConvertDocsToArr } from '~/utils/convert-docs-to-arr/convert-docs-to-arr';
import { DocumentTemplateType, UserInfoDataDocuments } from '~/utils/convert-docs-to-arr/type';
import { isArrayWithItems } from '~/utils/is-array-with-items';

type DocumentsFormBlockProps<T> = {
    form: FormInstance<T>;
    documentsTemplate: DocumentTemplateType[];
    docType: number;
    draft?: UserInfoDataDocuments;
    data?: UserInfoResponse;
    disabled?: boolean;
};

export const DocumentsFormBlock = <T,>({
    data,
    draft,
    documentsTemplate,
    docType,
    form,
    disabled = false,
}: DocumentsFormBlockProps<T>) => {
    const documents = draft
        ? data?.userInfo?.documents?.filter((doc) => doc.type !== draft.type) || []
        : data?.userInfo?.documents || [];

    const arrDocuments = draft ? [draft, ...documents] : documents;
    const updatedDocuments = arrDocuments.filter((doc) => doc.type && doc.type !== 0);

    useEffect(() => {
        form.resetFields(RESET_ARRAY);
    }, [docType, form]);

    return (
        <div className={styles.container}>
            <div className={styles.applicationTabSubtitle}>{PRIVATE_ROOM_SUBTITLE.documents}</div>
            <div className={styles.applicationTabBlock}>
                <CustomSelect
                    label={PRIVATE_ROOM.inputs.identityDocuments[0].label}
                    keyName={PRIVATE_ROOM.inputs.identityDocuments[0].keyName}
                    key={PRIVATE_ROOM.inputs.identityDocuments[0].keyName}
                    rules={[VALIDATION_RULES.required]}
                    options={PRIVATE_ROOM.inputs.identityDocuments[0].options}
                    disabled={disabled}
                />
                {!isArrayWithItems(updatedDocuments) &&
                    documentsTemplate.map(({ label, keyName, type, mask }) => {
                        switch (type) {
                            case PrivateRoomInput.calendar:
                                return (
                                    <CustomDataPicker
                                        label={label}
                                        keyName={keyName}
                                        key={keyName}
                                        rules={getRulesForDocuments(keyName, docType)}
                                    />
                                );
                            default:
                                return (
                                    <CustomMaskInput
                                        label={
                                            keyName === PrivateRoomInputsKeyName.docNumber &&
                                            docType > Doc.FidDoc
                                                ? docNumberLabel
                                                : label
                                        }
                                        keyName={keyName}
                                        key={keyName}
                                        rules={getRulesForDocuments(keyName, docType)}
                                        mask={mask[docType - 1]}
                                    />
                                );
                        }
                    })}
                {isArrayWithItems(updatedDocuments) &&
                    ConvertDocsToArr(
                        docType,
                        documentsTemplate,
                        draft?.type,
                        updatedDocuments as UserInfoDataDocuments[],
                    ).map(({ name, label, type, mask, isDisabled, rules }) => {
                        const currentValue = form.getFieldValue(name[0]);

                        switch (type) {
                            case PrivateRoomInput.calendar:
                                return (
                                    <CustomDataPicker
                                        label={label}
                                        keyName={name[0]}
                                        key={label}
                                        rules={rules}
                                        currentValue={currentValue}
                                        disabled={isDisabled}
                                    />
                                );
                            default:
                                return (
                                    <CustomMaskInput
                                        label={label}
                                        keyName={name[0]}
                                        key={label}
                                        rules={rules}
                                        mask={mask}
                                        disabled={isDisabled}
                                        currentValue={currentValue}
                                    />
                                );
                        }
                    })}
            </div>
        </div>
    );
};
