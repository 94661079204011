/* eslint-disable @typescript-eslint/no-explicit-any */

import dayjs from 'dayjs';

import { UserInfoTemp } from '../types/form-types';

import { DATE_FORMAT } from '~/constants/general';

export function getInitialFormValuesWithDraft(draft?: UserInfoTemp | null) {
    if (draft) {
        const { document, ...rest } = draft;

        return Object.entries(rest).reduce((acc: Record<string, any>, [key, value]) => {
            let formattedValue;

            if (key === 'birthDate') {
                formattedValue = draft.birthDate && dayjs(String(draft.birthDate), DATE_FORMAT);
            } else if (
                ['countryId', 'citizenshipId', 'gender'].includes(key) &&
                (value === 0 || !value)
            ) {
                formattedValue = undefined;
            } else if (key === 'mobile') {
                formattedValue =
                    value && `+7(${String(value).slice(1, 4)})${String(value).slice(4)}`;
            } else {
                formattedValue = String(value);
            }

            return {
                ...acc,
                [key]: formattedValue,
            };
        }, {});
    }

    return undefined;
}
