import React, { useEffect, useRef, useState } from 'react';
import { Divider, Form } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { CustomForm } from 'shared/form-components/custom-form';
import { FormSelect } from 'shared/form-components/form-select';
import { FormWrapper } from 'shared/form-components/form-wrapper';

import { CheckDocumentTitle } from './components/check-document-title';
import { CheckDocumentUpload } from './components/check-document-upload';

import styles from './check-document.module.css';

import { AdditionalChecks } from '~/components/check-and-sign-certificate/content/check-certificate/additional-checks';
import {
    SIGNATURE_SELECTION_OPTIONS,
    SignatureSelection,
} from '~/components/check-and-sign-certificate/content/check-document/constants/signature-selection-options';
import { returnBase64Array } from '~/components/check-and-sign-certificate/content/check-document/helpers/return-base64-array';
import { returnItemsForCollapse } from '~/components/check-and-sign-certificate/content/check-document/helpers/return-items-for-collapse';
import {
    CheckDocumentsFields,
    FormTypes,
} from '~/components/check-and-sign-certificate/content/check-document/types/form-types';
import { useCheckSignatureDocument } from '~/components/check-and-sign-certificate/content/hooks/use-check-signature-document';
import { useCompareValue } from '~/components/check-and-sign-certificate/content/hooks/use-compare-value';
import { useGetSvsPolicy } from '~/components/check-and-sign-certificate/content/hooks/use-get-svs-policy';
import { BUTTON_TEXT } from '~/constants/button';
import { FORMS_DATA } from '~/constants/forms-data';
import { VALIDATION_FIELD_RULES } from '~/constants/general';
import { CustomButton } from '~/shared/custom-button';
import { CustomRow } from '~/shared/custom-row';
import { ResultBlockWithCollapse } from '~/shared/result-block-with-collapse';
import { convertToBase64 } from '~/utils/convert-to-base64';
import { handleFormError } from '~/utils/handle-form-error';
import { isArrayWithItems } from '~/utils/is-array-with-items';

export const CheckDocument = () => {
    const [form] = Form.useForm<FormTypes>();

    const {
        isLoadingGetSvsPolicy,
        isCertificateVerifierExist,
        certificateVerifiers,
        signatureDescriptions,
    } = useGetSvsPolicy();
    const {
        isError,
        isSuccess,
        isLoadingCheckSignatureDocument,
        signatureDocument,
        handleManualVerification,
        handleAutoInstall,
    } = useCheckSignatureDocument(signatureDescriptions);
    const fileNameRef = useRef<null | string>(null);

    const currentFileExist = isArrayWithItems(useWatch(CheckDocumentsFields.content, form));

    const isShowCheckAnotherSigButton =
        currentFileExist && fileNameRef.current && (isSuccess || isError);

    const [disabledFormElements, setDisabledFormElements] = useState(false);

    const currentSignatureSelect = useWatch(CheckDocumentsFields.signatureSelection, form);

    const onSave = async () => {
        try {
            const {
                content,
                autoInstallSignatureType,
                signatureType,
                certVerifiersPluginsIds,
                signatureSelection,
                certificate,
                rowSignature,
                hashFunctionSelection,
            } = await form.validateFields();

            // TODO временно закоменченная функциональность
            // const isAutoInstall = autoInstallSignatureType === AutoInstallSignature.auto;
            // const isGOST3410 = signatureType === Signature.GOST3410;
            // const isDataSignature = rowSignature === RowSignature.dataSignature;
            const isDetachedSignature = signatureSelection === SignatureSelection.unpinnedSignature;

            const { base64Array, originalDocument } = await returnBase64Array(content);
            const base64Certificate =
                certificate &&
                (await Promise.all(
                    certificate.map(async ({ originFileObj }) =>
                        originFileObj ? convertToBase64(originFileObj) : null,
                    ),
                ));

            // TODO временно закрменченная функциональность
            // if (isAutoInstall) {
            //     handleAutoInstall({
            //         base64Array,
            //         isDetachedSignature,
            //         content,
            //         certVerifiersPluginsIds,
            //     });
            // } else {
            //     handleManualVerification({
            //         base64Array,
            //         isDetachedSignature,
            //         isDataSignature,
            //         signatureType,
            //         isGOST3410,
            //         base64Certificate,
            //         hashFunctionSelection,
            //         certVerifiersPluginsIds,
            //     });
            // }
            handleAutoInstall({
                base64Array,
                isDetachedSignature,
                content,
                certVerifiersPluginsIds,
            });

            fileNameRef.current = originalDocument;
            setDisabledFormElements(true);
        } catch (error) {
            handleFormError(form, error);
            setDisabledFormElements(false);
        }
    };

    const isLoading = isLoadingGetSvsPolicy || isLoadingCheckSignatureDocument;

    const needToShowResult =
        currentFileExist && fileNameRef.current && !isLoadingCheckSignatureDocument;

    const { hasChanged } = useCompareValue(currentSignatureSelect);

    const itemsForCollapse = returnItemsForCollapse(
        currentSignatureSelect === SignatureSelection.pinnedSignature
            ? ''
            : fileNameRef.current ?? '',
        signatureDocument?.verificationResult,
    );

    useEffect(() => {
        if (!currentFileExist && fileNameRef.current) {
            form.setFieldValue(CheckDocumentsFields.content, []);
            form.setFieldValue(CheckDocumentsFields.certVerifiersPluginsIds, []);
            fileNameRef.current = null;
            setDisabledFormElements(false);
        }
    }, [currentFileExist, form]);

    useEffect(() => {
        if (currentFileExist && hasChanged) {
            form.setFieldValue(CheckDocumentsFields.content, []);
        }
    }, [currentFileExist, form, hasChanged]);

    const checkAnotherDocumentHandler = () => {
        fileNameRef.current = null;
        form.setFieldValue(CheckDocumentsFields.signatureSelection, undefined);
        setDisabledFormElements(false);
    };

    return (
        <FormWrapper
            header={<CheckDocumentTitle />}
            footer={
                <CustomButton
                    className='applicationTabButton'
                    disabled={isLoading}
                    onClick={isShowCheckAnotherSigButton ? checkAnotherDocumentHandler : onSave}
                >
                    {isShowCheckAnotherSigButton
                        ? BUTTON_TEXT.checkAnotherDocument
                        : BUTTON_TEXT.checkCertificate}
                </CustomButton>
            }
        >
            <CustomForm layout='vertical' form={form} disabled={isLoading}>
                <CustomRow columnSpan={24} rowGutter={[0, 12]}>
                    <React.Fragment key='select with document upload'>
                        <FormSelect
                            style={{ width: '410px' }}
                            label={FORMS_DATA.checkDocumentForm.continueDownloadingTheFile}
                            name={CheckDocumentsFields.signatureSelection}
                            rules={VALIDATION_FIELD_RULES}
                            selectProps={{ options: SIGNATURE_SELECTION_OPTIONS }}
                            hasEmptyOption={false}
                            className={styles.select}
                        />

                        <CheckDocumentUpload
                            form={form}
                            disabledFormElements={disabledFormElements}
                            loading={isLoadingCheckSignatureDocument}
                        />
                        {currentFileExist && <Divider style={{ marginBottom: 0, marginTop: 0 }} />}
                    </React.Fragment>
                    {currentFileExist && (
                        <React.Fragment>
                            {/* TODO временно закрменченная функциональность */}
                            {/* <SignatureParameters */}
                            {/*    form={form} */}
                            {/*    isLoading={isLoadingCheckSignatureDocument} */}
                            {/* /> */}
                            {isCertificateVerifierExist && (
                                <AdditionalChecks
                                    disabled={disabledFormElements}
                                    form={form}
                                    certificateVerifiers={certificateVerifiers}
                                />
                            )}
                        </React.Fragment>
                    )}
                </CustomRow>
                {needToShowResult && (
                    <ResultBlockWithCollapse itemsForCollapse={itemsForCollapse} />
                )}
            </CustomForm>
        </FormWrapper>
    );
};
