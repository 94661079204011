import { ColumnsType } from 'antd/es/table';
import { FilterDropdownProps } from 'antd/es/table/interface';

import { RangePickerDropdown } from '../../certificates-table/range-picker-dropdown';

import { CERTIFICATES_STATUS_INFO, StatusCode } from '~/constants/certificates';
import { CERTIFICATES_COLUMNS, CertificatesColumnsName } from '~/constants/certificates-table';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { CertificatesTitle } from '~/shared/certificates-title';
import { CustomTag } from '~/shared/custom-tag';
import { CurrentPageTable } from '~/utils/transform-certificates-data/type';
import { transformTitleToCertificateTable } from '~/utils/transform-title-to-certificate-table';

type ReturnColumnsForArchiveTableType = {
    allTitles: Array<string>;
    currentPage: CurrentPageTable[];
};
export const returnColumnsForArchiveTable = ({
    allTitles,
    currentPage,
}: ReturnColumnsForArchiveTableType): ColumnsType<CurrentPageTable> => {
    const filterDropdown = ({ ...props }: FilterDropdownProps) => (
        <RangePickerDropdown filterName={LOCAL_STORAGE.filtersForArchives} {...props} />
    );

    return [
        {
            key: CertificatesColumnsName.title,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.title],
            dataIndex: CertificatesColumnsName.title,
            filters: allTitles?.map((item) => ({
                text: transformTitleToCertificateTable(item, currentPage),
                value: item,
            })),
            filterOnClose: false,
            filterSearch: true,
            sorter: true,
            render: (text) => <CertificatesTitle title={text} />,
        },
        {
            key: CertificatesColumnsName.statusTag,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.statusTag],
            dataIndex: CertificatesColumnsName.statusTag,
            filters: Object.keys(CERTIFICATES_STATUS_INFO)
                .filter(
                    (_, idx) =>
                        idx !== StatusCode.ApplicationCompleted && idx !== StatusCode.Draft - 1,
                )
                .map((element) => ({
                    text: CERTIFICATES_STATUS_INFO[Number(element) as StatusCode].titleStatus,
                    value: Number(element),
                })),
            filterOnClose: false,
            filterSearch: true,
            sorter: true,
            render: (item) => <CustomTag title={item.titleStatus} color={item.color} />,
        },
        {
            key: CertificatesColumnsName.lastEditDate,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.lastEditDate],
            dataIndex: CertificatesColumnsName.lastEditDate,
            filterDropdown,
            filterSearch: true,
            sorter: true,
            width: 'var(--width-for-column-150)',
        },
    ];
};
