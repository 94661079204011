import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Select } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import { FormInstance } from 'antd/lib';
import classNames from 'classnames';
import { CustomForm } from 'shared/form-components/custom-form';
import { FormWrapper } from 'shared/form-components/form-wrapper';

import { FormBody } from './form-body';
import { FormModal } from './form-modal';

import styles from './application-tab.module.css';

import { FormPlaceholders, RESET_ARRAY } from '~/components/application-tab/constants/general';
import { getInitialFormValuesCommonBlock } from '~/components/application-tab/helpers/get-initial-form-values-common-block';
import { getInitialFormValuesDocumentsBlock } from '~/components/application-tab/helpers/get-initial-form-values-documents-block';
import { getInitialFormValuesWithDraft } from '~/components/application-tab/helpers/get-initial-form-values-with-draft';
import { parseTemplateString } from '~/components/application-tab/helpers/parse-template-string';
import { preparationToSendForServer } from '~/components/application-tab/helpers/preparation-to-send-for-server';
import { FormTypes, UserInfoTemp } from '~/components/application-tab/types/form-types';
import { BASE_PARAMS } from '~/components/certificates-table/constants/general';
import { BUTTON_TEXT } from '~/constants/button';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { Doc, PrivateRoomInputsKeyName } from '~/constants/private-room';
import { ROUTES } from '~/constants/routes';
import { stepName } from '~/constants/step-name';
import { WithLoader } from '~/hoc/with-loader';
import { useAppDispatch, useAppSelector } from '~/hooks';
import { PrivateRoomTabsName } from '~/pages/private-room/constants/tabs-name';
import {
    useCreateCertificateRequestMutation,
    useCreateDraftRequestMutation,
    useLazyGetDraftQuery,
    useRemoveDraftMutation,
} from '~/rtk-queries/endpoints/certificates-list';
import { useGetTemplateQuery } from '~/rtk-queries/endpoints/template';
import { useGetUserInfoMutation } from '~/rtk-queries/endpoints/user-info';
import { CustomButton } from '~/shared/custom-button';
import { setOpenModalForMoveFromApplicationTab } from '~/store/app-tab/app-tab-slice';
import { draftSelector } from '~/store/draft/get-draft-slice';
import { CommonResponse, Nullable, OptionType, ValidateErrorEntity } from '~/types/common';
import { cleanupAfterCertificateCreation } from '~/utils/clean-after-creation';
import { convertTemplatesToOptions } from '~/utils/convert-templates-to-options';
import { getLocalStorageItem } from '~/utils/local-storage';

export const ApplicationTab = () => {
    const { tabName } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const draftData = useAppSelector(draftSelector);

    const esiaLogin = Boolean(getLocalStorageItem(LOCAL_STORAGE.isEsiaLogin));
    const storedDraftData = getLocalStorageItem(LOCAL_STORAGE.draftData);

    const [form] = useForm<Nullable<Partial<FormTypes>>>();
    const [step, setStep] = useState(stepName.one);
    const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
    const isCreateCertificateRef = useRef(false);
    const isCreateDraftRef = useRef(false);
    const [optionsTemplates, setOptionsTemplates] = useState<OptionType[]>([]);
    const [description, setDescription] = useState<string>('');
    const [selectedTemplateName, setSelectedTemplateName] = useState('');
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [draft, setDraft] = useState<UserInfoTemp | null>(null);
    const [folderId, setFolderId] = useState<number>(
        Number(JSON.parse(storedDraftData || '0').folderId),
    );
    const [templateId, setTemplateId] = useState<number>(
        Number(JSON.parse(storedDraftData || '0').templateId),
    );

    const { data: templatesData, isFetching: isFetchingTemplate } = useGetTemplateQuery({});
    const [getDraft, { data: draftInfo, isLoading: isLoadingDraftInfo }] = useLazyGetDraftQuery();
    const [removeDraft, { isLoading: isLoadingRemoveDraft }] = useRemoveDraftMutation();
    const [
        getUserInfo,
        {
            data: userInfoResponse,
            isLoading: isLoadingUserInfo,
            isSuccess: isSuccessUserInfo,
            isError: isErrorUserInfo,
            error: userInfoError,
        },
    ] = useGetUserInfoMutation();
    const [
        postCreateCertificateRequest,
        {
            data: createCertificateData,
            isSuccess: isSuccessCreateCertificate,
            isLoading: isLoadingCreateCertificate,
        },
    ] = useCreateCertificateRequestMutation();
    const [
        postCreateDraftRequest,
        {
            data: createDraftData,
            isSuccess: isSuccessDraftCertificate,
            isLoading: isLoadingDraftCertificate,
        },
    ] = useCreateDraftRequestMutation();

    const certificateTemplate = useWatch(PrivateRoomInputsKeyName.certificateTemplate, form);
    const docType: Doc =
        useWatch(PrivateRoomInputsKeyName.type, form) ||
        draft?.document?.type ||
        userInfoResponse?.userInfo?.documents?.[0]?.type ||
        Doc.Empty;

    const isLoading =
        isFetchingTemplate ||
        isLoadingUserInfo ||
        isLoadingCreateCertificate ||
        isLoadingDraftCertificate ||
        isLoadingDraftInfo ||
        isLoadingRemoveDraft;

    const isErrorModal =
        Boolean(createCertificateData?.hasError) ||
        Boolean(createDraftData?.hasError) ||
        Boolean(userInfoResponse?.hasError);

    let errorData;
    let isTemplateError;

    if (userInfoError && 'data' in userInfoError) {
        errorData = userInfoError.data as CommonResponse;
        isTemplateError = errorData?.message?.includes("doesn't contain specified template");
    }

    const isDraftError = !!draft && isErrorUserInfo && isTemplateError;
    const disableButton = isLoadingCreateCertificate || isLoadingDraftCertificate || isDraftError;
    const isViewModal = isSuccessCreateCertificate || isSuccessDraftCertificate || isDraftError;

    let createData;

    if (isDraftError) {
        createData = errorData;
    } else {
        createData = isCreateCertificateRef.current ? createCertificateData : createDraftData;
    }

    const deleteDraft = useCallback(async () => {
        const { RequestId } = JSON.parse(storedDraftData || '');

        await removeDraft({ RequestId });
    }, [storedDraftData, removeDraft]);

    const handleCertificateCreationCleanup = useCallback(
        (form: FormInstance) => {
            cleanupAfterCertificateCreation(form);
            setStep(stepName.one);
            setDraft(null);
            navigate(`${ROUTES.privateRoom}/${PrivateRoomTabsName.certificateTab}${BASE_PARAMS}`);
            setIsFirstRender(true);
        },
        [setStep, setDraft, navigate, setIsFirstRender],
    );

    const openSuccessModal = () => {
        setIsOpenSuccessModal(false);
        isCreateCertificateRef.current = false;
        isCreateDraftRef.current = false;
        if (isDraftError) {
            handleCertificateCreationCleanup(form);
            deleteDraft();
        }
    };

    const onClickStepOne = useCallback(() => {
        if (folderId && templateId) {
            getUserInfo({ folderId, templateId });
            setStep(stepName.two);
            dispatch(setOpenModalForMoveFromApplicationTab(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, folderId, templateId]);

    const initFormValuesCommonBlock = useMemo(() => {
        if (userInfoResponse?.userInfo) {
            const dataCombined = {
                ...userInfoResponse?.userInfo?.address,
                ...userInfoResponse?.userInfo?.main,
                ...userInfoResponse?.userInfo?.other,
            };

            const formValues = getInitialFormValuesCommonBlock(dataCombined);

            if (draft) {
                const draftWithStringValues = getInitialFormValuesWithDraft({
                    ...draft,
                });

                if (esiaLogin) {
                    Object.entries(dataCombined).forEach(([key, value]) => {
                        if (value?.enabled && draftWithStringValues?.[key]) {
                            formValues[key] = draftWithStringValues[key];
                        }
                    });

                    return formValues;
                }

                return {
                    ...formValues,
                    ...draftWithStringValues,
                };
            }

            return formValues;
        }

        return {};
    }, [draft, esiaLogin, userInfoResponse?.userInfo]);

    const initFormValuesDocumentsBlock = useMemo(() => {
        if (userInfoResponse?.userInfo?.documents) {
            const documents = draft
                ? [draft.document, ...userInfoResponse.userInfo.documents]
                : userInfoResponse.userInfo.documents;
            const formDocumentValues = getInitialFormValuesDocumentsBlock(documents, docType);

            return formDocumentValues;
        }

        return {};
    }, [userInfoResponse?.userInfo?.documents, draft, docType]);

    const onSave = async () => {
        try {
            const formValues = await form.validateFields();

            const newFormValues = preparationToSendForServer(formValues);

            const value = {
                folderId,
                templateId,
                userInfoTemp: { ...newFormValues, id: userInfoResponse?.userInfo.id },
            };

            postCreateCertificateRequest(value);
            isCreateCertificateRef.current = true;
            setIsOpenSuccessModal(true);
        } catch (error) {
            const errorObj = error as ValidateErrorEntity<FormTypes>;

            form.scrollToField(errorObj.errorFields[0].name[0], {
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    const onSaveDraft = async () => {
        try {
            const formValues = await form.getFieldsValue();

            const newFormValues = preparationToSendForServer(formValues);

            const requestId = storedDraftData ? JSON.parse(storedDraftData).RequestId : null;
            const userInfoTempData = {
                ...newFormValues,
                id: userInfoResponse?.userInfo.id,
            };

            const value = {
                requestId,
                folderId,
                templateId,
                userInfoTemp: JSON.stringify(userInfoTempData),
            };

            postCreateDraftRequest(value);
            isCreateDraftRef.current = true;
            setIsOpenSuccessModal(true);
        } catch (error) {
            const errorObj = error as ValidateErrorEntity<FormTypes>;

            form.scrollToField(errorObj.errorFields[0].name[0], {
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    useEffect(() => {
        if (isDraftError) {
            setIsOpenSuccessModal(true);
        }
    }, [isDraftError]);

    useEffect(() => {
        if (tabName === PrivateRoomTabsName.applicationTab && storedDraftData) {
            const { RequestId } = JSON.parse(storedDraftData);

            if (!draftData.userInfoTemp) {
                getDraft({ RequestId });
                setDraft(JSON.parse(draftInfo?.userInfoTemp || 'null'));
            } else {
                setDraft(JSON.parse(draftData.userInfoTemp));
            }
        }
    }, [tabName, storedDraftData, draftData, getDraft, draftInfo]);

    useEffect(() => {
        if (tabName === PrivateRoomTabsName.applicationTab && storedDraftData) {
            setTemplateId(Number(JSON.parse(storedDraftData).templateId));
            setFolderId(Number(JSON.parse(storedDraftData).folderId));
            onClickStepOne();
        }
    }, [tabName, storedDraftData, onClickStepOne]);

    useLayoutEffect(() => {
        if (tabName === PrivateRoomTabsName.applicationTab && !storedDraftData) {
            const options = convertTemplatesToOptions(templatesData?.templates);
            const templateInfo = parseTemplateString(certificateTemplate);

            setFolderId(templateInfo.folderId || 0);
            setTemplateId(templateInfo.templateId || 0);
            setOptionsTemplates(options);
            setDescription(templateInfo.description || '');
        }
    }, [certificateTemplate, templatesData, tabName, storedDraftData]);

    useLayoutEffect(() => {
        if (tabName !== PrivateRoomTabsName.applicationTab) {
            setDraft(null);
            form.resetFields();
            setStep(stepName.one);
            setIsFirstRender(true);
        }
    }, [form, tabName, setStep]);

    useEffect(() => {
        if (storedDraftData && isSuccessCreateCertificate && isCreateCertificateRef.current) {
            deleteDraft();
        }
    }, [deleteDraft, isSuccessCreateCertificate, storedDraftData]);

    useEffect(() => {
        if (templateId && folderId) {
            const foundTemplate = templatesData?.templates.find(
                (elem) => elem.id === Number(templateId) && elem.folderId === Number(folderId),
            );

            setSelectedTemplateName(foundTemplate?.displayName || '');
        }
    }, [templateId, templatesData?.templates, folderId]);

    // перередирект на другую вкладку
    useLayoutEffect(() => {
        if (
            (isSuccessCreateCertificate && isCreateCertificateRef.current) ||
            (isSuccessDraftCertificate && isCreateDraftRef.current)
        ) {
            handleCertificateCreationCleanup(form);
        }
    }, [
        form,
        handleCertificateCreationCleanup,
        isSuccessCreateCertificate,
        isSuccessDraftCertificate,
    ]);

    // срабатывает при первом рендере
    useEffect(() => {
        if (tabName === PrivateRoomTabsName.applicationTab) {
            const initFormValues = {
                ...initFormValuesCommonBlock,
                ...initFormValuesDocumentsBlock,
            };

            form.setFieldsValue(initFormValues);
            setIsFirstRender(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccessUserInfo]);

    // срабатывает при последующих перерендерах
    useEffect(() => {
        if (!isFirstRender) {
            const currentFields = form.getFieldsValue();

            form.setFieldsValue({ ...currentFields, ...initFormValuesDocumentsBlock });

            if (!initFormValuesDocumentsBlock) {
                form.resetFields(RESET_ARRAY);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initFormValuesDocumentsBlock]);

    return (
        <WithLoader isLoading={isLoading} className={styles.spinner}>
            <FormWrapper>
                <CustomForm form={form} className={styles.applicationTabForm} layout='vertical'>
                    <div
                        className={classNames(styles.applicationTabBlocks, {
                            [styles.active]: step === stepName.one,
                        })}
                    >
                        <FormItem
                            className={styles.certificateTemplate}
                            label={false}
                            name={PrivateRoomInputsKeyName.certificateTemplate}
                        >
                            <Select
                                options={optionsTemplates}
                                placeholder={FormPlaceholders.CertificateTemplateSelect}
                            />
                        </FormItem>
                        <div>{description}</div>
                        <CustomButton
                            className='applicationTabButton'
                            onClick={onClickStepOne}
                            disabled={!certificateTemplate}
                        >
                            {BUTTON_TEXT.continue}
                        </CustomButton>
                    </div>
                    <FormBody
                        step={step}
                        form={form}
                        docType={docType}
                        onSaveDraft={onSaveDraft}
                        onSave={onSave}
                        cleanupAfterCertificateCreation={handleCertificateCreationCleanup}
                        setStep={setStep}
                        userInfoResponse={userInfoResponse}
                        draft={draft}
                        disableButton={disableButton}
                    />
                </CustomForm>
                {isViewModal && (
                    <FormModal
                        isOpenSuccessModal={isOpenSuccessModal}
                        setIsOpenSuccessModal={setIsOpenSuccessModal}
                        openSuccessModal={openSuccessModal}
                        isErrorModal={isErrorModal}
                        selectedTemplateName={selectedTemplateName}
                        isDraftError={isDraftError}
                        createData={createData}
                    />
                )}
            </FormWrapper>
        </WithLoader>
    );
};
