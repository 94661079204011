import { FrontConfigKey, FrontConfigType } from '../get-front-config/type';
import { isErrorPluginWithReason } from '../is-error-plugin-with-reason';

import { ERROR_REASONS } from './constants';

import {
    BrauserName,
    LINK_CADES_PLAGIN,
    LINK_PLATFORM_INSTRUCTIONS,
    PlatformName,
} from '~/constants/link-cades-plugin';

export const checkCadesExtension = (
    setCadesStatus: React.Dispatch<React.SetStateAction<boolean>>,
    setBrowserLink: React.Dispatch<React.SetStateAction<string>>,
    setPlatformLink: React.Dispatch<React.SetStateAction<string>>,
    frontConfig?: FrontConfigType,
) => {
    const { cadesplugin } = window;

    const browserLink = {
        [BrauserName.chrome]: frontConfig
            ? frontConfig[FrontConfigKey.ExtentionLinkChrome]
            : LINK_CADES_PLAGIN[BrauserName.chrome],
        [BrauserName.edg]: frontConfig
            ? frontConfig[FrontConfigKey.ExtentionLinkEdge]
            : LINK_CADES_PLAGIN[BrauserName.edg],
        [BrauserName.yaBrowser]: frontConfig
            ? frontConfig[FrontConfigKey.ExtentionLinkYandex]
            : LINK_CADES_PLAGIN[BrauserName.yaBrowser],
        [BrauserName.opera]: frontConfig
            ? frontConfig[FrontConfigKey.ExtentionLinkOpera]
            : LINK_CADES_PLAGIN[BrauserName.opera],
        [BrauserName.firefox]: frontConfig
            ? frontConfig[FrontConfigKey.ExtentionLinkFirefox]
            : LINK_CADES_PLAGIN[BrauserName.firefox],
        [BrauserName.safari]: frontConfig
            ? frontConfig[FrontConfigKey.ExtentionLinkDefault]
            : LINK_CADES_PLAGIN[BrauserName.safari],
        [BrauserName.ie]: frontConfig
            ? frontConfig[FrontConfigKey.ExtentionLinkIe]
            : LINK_CADES_PLAGIN[BrauserName.ie],
        [BrauserName.msie]: frontConfig
            ? frontConfig[FrontConfigKey.ExtentionLinkMsie]
            : LINK_CADES_PLAGIN[BrauserName.msie],
    };

    setBrowserLink(
        browserLink[cadesplugin.browserSpecs.name as BrauserName] ??
            (frontConfig
                ? frontConfig[FrontConfigKey.ExtentionLinkDefault]
                : LINK_CADES_PLAGIN.default),
    );

    const PlatformLink = {
        [PlatformName.windows]: frontConfig
            ? frontConfig[FrontConfigKey.InstructionLinkWindows]
            : LINK_PLATFORM_INSTRUCTIONS[PlatformName.windows],
        [PlatformName.macOS]: frontConfig
            ? frontConfig[FrontConfigKey.InstructionLinkMacOS]
            : LINK_PLATFORM_INSTRUCTIONS[PlatformName.macOS],
        [PlatformName.aurora]: frontConfig
            ? frontConfig[FrontConfigKey.InstructionLinkAurora]
            : LINK_PLATFORM_INSTRUCTIONS[PlatformName.aurora],
        [PlatformName.linux]: frontConfig
            ? frontConfig[FrontConfigKey.InstructionLinkLinux]
            : LINK_PLATFORM_INSTRUCTIONS[PlatformName.linux],
    };

    setPlatformLink(
        PlatformLink[cadesplugin.platformCheck() as PlatformName] ??
            (frontConfig
                ? frontConfig[FrontConfigKey.InstructionLinkDefault]
                : LINK_PLATFORM_INSTRUCTIONS.default),
    );
    cadesplugin
        .then(() => {
            setCadesStatus(true);
        })
        .catch((err: unknown) => {
            if (isErrorPluginWithReason(err) && err.reason === ERROR_REASONS.EXTENSION) {
                setCadesStatus(false);
            }
        });
};
