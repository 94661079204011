import { Rule } from 'antd/es/form';

import { VALIDATION_RULES } from '~/constants/general';

type rulesInputType = {
    required?: boolean;
    regex?: string;
    minLength?: number;
    maxLength?: number;
};

export const rulesInput = ({ required, regex, maxLength, minLength }: rulesInputType) => {
    const arrRules = [] as Rule[];

    if (required) {
        arrRules.push(VALIDATION_RULES.required);
    }
    if (regex) {
        arrRules.push({
            pattern: new RegExp(regex),
            message: 'Не соответствует формату',
        });
    }
    if (minLength) {
        arrRules.push({
            min: minLength,
            message: `Минимальная длина поля ${minLength} символов`,
        });
    }
    if (maxLength) {
        arrRules.push({
            max: maxLength,
            message: `Максимальная длина поля ${maxLength} символов`,
        });
    }

    return arrRules;
};
