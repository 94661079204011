import { VALIDATION_RULES } from '~/constants/general';
import { PrivateRoomTabsName } from '~/pages/private-room/constants/tabs-name';
import { DocumentTemplateType } from '~/utils/convert-docs-to-arr/type';
import { generateRulesForDocType } from '~/utils/generate-rules-for-doc-type';

export enum PrivateRoomInput {
    text = 'text',
    number = 'number',
    email = 'email',
    select = 'select',
    tel = 'tel',
    calendar = 'calendar',
}

export enum PrivateRoomInputsKeyName {
    ownerType = 'OwnerType',
    mode = 'Mode',
    template = 'template',
    lastName = 'lastName',
    firstName = 'firstName',
    middleName = 'middleName',
    inn = 'inn',
    snils = 'snils',
    mobile = 'mobile',
    email = 'email',
    type = 'type',
    docSeries = 'series',
    docNumber = 'number',
    docIssueDate = 'issueDate',
    docIssueId = 'issueId',
    docIssuedBy = 'issuedBy',
    docExpiryDate = 'expiryDate',
    gender = 'gender',
    birthDate = 'birthDate',
    birthPlace = 'birthPlace',
    citizenshipId = 'citizenshipId',
    orgName = 'orgName',
    orgUnit = 'orgUnit',
    countryId = 'countryId',
    region = 'region',
    city = 'city',
    addressString = 'addressString',
    certificateTemplate = 'certificateTemplate',
    document = 'document',
    jobTitle = 'jobTitle',
}

export enum OwnerType {
    PSO = 'PSO',
    ORG = 'ORG',
    IB = 'IB',
    SYS = 'SYS',
    IBS = 'IBS',
    TlsClient = 'TlsClient',
    ForeignOrg = 'ForeignOrg',
}

export enum ModeType {
    Issue = 'Issue',
    Full = 'Full',
    FullNoWait = 'FullNoWait',
}

export enum Doc {
    Empty = 0,
    RfPassport = 1,
    FidDoc = 2,
    TempRes = 3,
    ResPermit = 4,
}

export const DOC_TYPE_VALUE = {
    Empty: '',
    RfPassport: 'Паспорт РФ',
    FidDoc: 'Документ иностранного гражданина',
    TempRes: 'Разрешение на временное проживание',
    ResPermit: 'Вид на жительство',
};

export enum Gender {
    unknown = '',
    male = '1',
    female = '2',
}

export const GENDER_TYPE_VALUE = {
    unknown: '',
    male: 'Мужской',
    female: 'Женский',
};

export const PRIVATE_ROOM_SUBTITLE = {
    data: 'Данные сертификата',
    main: 'Основные данные',
    documents: 'Документ, удостоверяющий личность',
    address: 'Адрес места жительства',
    other: 'Дополнительные данные',
};

export const PRIVATE_ROOM_MAIN = [
    'Фамилия',
    'Имя',
    'Отчество',
    'СНИЛС',
    'ИНН',
    'Гражданство',
    'День рождения',
    'Пол',
    'Место рождения',
    'Мобильный',
    'Адрес E-Mail',
];

export const MASK_PATTERN = {
    phone: '+7(000)0000000',
    phoneRegExp: '^(\\+7)?(\\([0-9]{3}\\))?[0-9]{7}$',
    cirylicSimbols: '^[-/ а-яА-Я]{1,}$',
    latinSimbols: '',
    latinCirylicSimbols: '',
    allSymbols: '',
    numbers1: '',
    numbersFour: '^[0-9]{4}$',
    numbersEleven: '000-000-000 00',
    numbersTwelwe: '^[0-9]{0,12}$',
    empty: '',
    emptyArr: ['', '', '', ''],
    series: ['00 00', '', '', ''],
    numbers: ['000000', '^[0-9a-zA-Z]{0,2}?[0-9]{0,7}$', '^[0-9]{1,9}$', '^[0-9]{1,9}$'],
    issueId: ['000 000', '', '', ''],
};

export const PATTERN_RULES_DOCS = {
    series: ['^[0-9]{2}(\\ )?[0-9]{2}$', '', '', ''],
    numbers: ['^[0-9]{6}$', '^[0-9a-zA-Z]{0,2}?[0-9]{1,7}$', '^[0-9]{0,9}$', '^[0-9]{0,9}$'],
    issueId: ['^[0-9]{3}(\\ )?[0-9]{3}$', '', '', ''],
    issueDate: ['', '', '', ''],
};

export const PRIVATE_ROOM = {
    tabsLabel: {
        [PrivateRoomTabsName.applicationTab]: 'Новый сертификат',
        [PrivateRoomTabsName.certificateTab]: 'Перечень сертификатов',
        [PrivateRoomTabsName.checkCertificateTab]: 'Подписание и проверка',
        [PrivateRoomTabsName.mchdTab]: 'МЧД',
        [PrivateRoomTabsName.archiveTab]: 'Архив',
    },
    inputs: {
        personalData: [
            {
                label: 'Фамилия',
                keyName: PrivateRoomInputsKeyName.lastName,
                type: PrivateRoomInput.text,
                mask: MASK_PATTERN.cirylicSimbols,
                rules: [VALIDATION_RULES.required],
            },
            {
                label: 'Имя',
                keyName: PrivateRoomInputsKeyName.firstName,
                type: PrivateRoomInput.text,
                mask: MASK_PATTERN.cirylicSimbols,
                rules: [VALIDATION_RULES.required],
            },
            {
                label: 'Отчество',
                keyName: PrivateRoomInputsKeyName.middleName,
                type: PrivateRoomInput.text,
                mask: MASK_PATTERN.cirylicSimbols,
                rules: [VALIDATION_RULES.required],
            },
            {
                label: 'ИНН',
                keyName: PrivateRoomInputsKeyName.inn,
                type: PrivateRoomInput.text,
                mask: MASK_PATTERN.numbersTwelwe,
                rules: [VALIDATION_RULES.required],
            },
            {
                label: 'СНИЛС',
                keyName: PrivateRoomInputsKeyName.snils,
                type: PrivateRoomInput.text,
                mask: MASK_PATTERN.numbersEleven,
                rules: [VALIDATION_RULES.required],
            },
            {
                label: 'Мобильный',
                keyName: PrivateRoomInputsKeyName.mobile,
                type: PrivateRoomInput.tel,
                mask: MASK_PATTERN.phone,
            },
            {
                label: 'Адрес E-mail',
                keyName: PrivateRoomInputsKeyName.email,
                type: PrivateRoomInput.email,
                mask: MASK_PATTERN.empty,
                rules: [VALIDATION_RULES.required],
            },
            {
                label: 'Пол',
                keyName: PrivateRoomInputsKeyName.gender,
                type: PrivateRoomInput.select,
                mask: MASK_PATTERN.empty,
                rules: [VALIDATION_RULES.required],
                options: [
                    {
                        value: Gender.unknown,
                        label: GENDER_TYPE_VALUE.unknown,
                    },
                    {
                        value: Gender.male,
                        label: GENDER_TYPE_VALUE.male,
                    },
                    {
                        value: Gender.female,
                        label: GENDER_TYPE_VALUE.female,
                    },
                ],
            },
        ],

        identityDocuments: [
            {
                label: 'Тип документа',
                keyName: PrivateRoomInputsKeyName.type,
                type: PrivateRoomInput.select,
                mask: MASK_PATTERN.emptyArr,
                options: [
                    {
                        value: Doc.RfPassport,
                        label: DOC_TYPE_VALUE.RfPassport,
                    },
                    {
                        value: Doc.FidDoc,
                        label: DOC_TYPE_VALUE.FidDoc,
                    },
                    {
                        value: Doc.TempRes,
                        label: DOC_TYPE_VALUE.TempRes,
                    },
                    {
                        value: Doc.ResPermit,
                        label: DOC_TYPE_VALUE.ResPermit,
                    },
                ],
                rules: [VALIDATION_RULES.required],
            },
            {
                label: 'Серия',
                keyName: PrivateRoomInputsKeyName.docSeries,
                type: PrivateRoomInput.text,
                mask: MASK_PATTERN.series,
                rules: generateRulesForDocType(PATTERN_RULES_DOCS.series),
            },
            {
                label: 'Номер',
                keyName: PrivateRoomInputsKeyName.docNumber,
                type: PrivateRoomInput.number,
                mask: MASK_PATTERN.numbers,
                rules: generateRulesForDocType(PATTERN_RULES_DOCS.numbers),
            },
            {
                label: 'Дата выдачи',
                keyName: PrivateRoomInputsKeyName.docIssueDate,
                type: PrivateRoomInput.calendar,
                mask: MASK_PATTERN.emptyArr,
                rules: generateRulesForDocType(PATTERN_RULES_DOCS.issueDate),
            },
            {
                label: 'Код подразделения',
                keyName: PrivateRoomInputsKeyName.docIssueId,
                type: PrivateRoomInput.text,
                mask: MASK_PATTERN.issueId,
                rules: generateRulesForDocType(PATTERN_RULES_DOCS.issueId),
            },
            {
                label: 'Кем выдан',
                keyName: PrivateRoomInputsKeyName.docIssuedBy,
                type: PrivateRoomInput.text,
                mask: MASK_PATTERN.emptyArr,
                rules: generateRulesForDocType(PATTERN_RULES_DOCS.issueDate),
            },
            {
                label: 'Действителен до',
                keyName: PrivateRoomInputsKeyName.docExpiryDate,
                type: PrivateRoomInput.calendar,
                mask: MASK_PATTERN.emptyArr,
                rules: generateRulesForDocType(PATTERN_RULES_DOCS.issueDate),
            },
        ],
        addressOtherBlock: [
            {
                label: 'Страна/регион',
                keyName: PrivateRoomInputsKeyName.countryId,
                type: PrivateRoomInput.text,
                mask: MASK_PATTERN.empty,
                rules: [VALIDATION_RULES.required],
            },
            {
                label: 'Область',
                keyName: PrivateRoomInputsKeyName.region,
                type: PrivateRoomInput.text,
                mask: MASK_PATTERN.empty,
                rules: [VALIDATION_RULES.required],
            },
            {
                label: 'Город',
                keyName: PrivateRoomInputsKeyName.city,
                type: PrivateRoomInput.text,
                mask: MASK_PATTERN.empty,
                rules: [VALIDATION_RULES.required],
            },
            {
                label: 'Адрес',
                keyName: PrivateRoomInputsKeyName.addressString,
                type: PrivateRoomInput.text,
                mask: MASK_PATTERN.empty,
                rules: [VALIDATION_RULES.required],
            },
            {
                label: 'Организация',
                keyName: PrivateRoomInputsKeyName.orgName,
                type: PrivateRoomInput.text,
                mask: MASK_PATTERN.empty,
                rules: [VALIDATION_RULES.required],
            },
            {
                label: 'Подразделение',
                keyName: PrivateRoomInputsKeyName.orgUnit,
                type: PrivateRoomInput.text,
                mask: MASK_PATTERN.empty,
                rules: [VALIDATION_RULES.required],
            },
            {
                label: 'Должность',
                keyName: PrivateRoomInputsKeyName.jobTitle,
                type: PrivateRoomInput.text,
                mask: MASK_PATTERN.empty,
                rules: [VALIDATION_RULES.required],
            },
        ],
    },
};

export const docNumberLabel = 'Номер решения';

export const INPUTS_IF_DISPLAYNAME_NULL = [
    {
        label: 'Место рождения',
        keyName: PrivateRoomInputsKeyName.birthPlace,
        mask: MASK_PATTERN.empty,
        type: PrivateRoomInput.text,
    },
];

export const DOCUMENTS_INPUTS = {
    [PrivateRoomInputsKeyName.docSeries]: {
        label: 'Серия',
        keyName: PrivateRoomInputsKeyName.docSeries,
        type: PrivateRoomInput.text,
        rules: [VALIDATION_RULES.required],
        mask: MASK_PATTERN.series,
    },
    [PrivateRoomInputsKeyName.docNumber]: {
        label: 'Номер',
        keyName: PrivateRoomInputsKeyName.docNumber,
        type: PrivateRoomInput.number,
        rules: [VALIDATION_RULES.required],
        mask: MASK_PATTERN.numbers,
    },
    [PrivateRoomInputsKeyName.docIssueDate]: {
        label: 'Дата выдачи',
        keyName: PrivateRoomInputsKeyName.docIssueDate,
        type: PrivateRoomInput.calendar,
        rules: [VALIDATION_RULES.required],
        mask: MASK_PATTERN.emptyArr,
    },
    [PrivateRoomInputsKeyName.docIssueId]: {
        label: 'Код подразделения',
        keyName: PrivateRoomInputsKeyName.docIssueId,
        type: PrivateRoomInput.text,
        rules: [VALIDATION_RULES.required],
        mask: MASK_PATTERN.issueId,
    },
    [PrivateRoomInputsKeyName.docIssuedBy]: {
        label: 'Кем выдан',
        keyName: PrivateRoomInputsKeyName.docIssuedBy,
        type: PrivateRoomInput.text,
        rules: [VALIDATION_RULES.required],
        mask: MASK_PATTERN.emptyArr,
    },
    [PrivateRoomInputsKeyName.docExpiryDate]: {
        label: 'Действителен до',
        keyName: PrivateRoomInputsKeyName.docExpiryDate,
        type: PrivateRoomInput.calendar,
        rules: [VALIDATION_RULES.required],
        mask: MASK_PATTERN.emptyArr,
    },
};

export const DOCUMENTS_TEMPLATE_RF_PASPORT = [
    DOCUMENTS_INPUTS[PrivateRoomInputsKeyName.docSeries],
    DOCUMENTS_INPUTS[PrivateRoomInputsKeyName.docNumber],
    DOCUMENTS_INPUTS[PrivateRoomInputsKeyName.docIssueDate],
    DOCUMENTS_INPUTS[PrivateRoomInputsKeyName.docIssueId],
    DOCUMENTS_INPUTS[PrivateRoomInputsKeyName.docIssuedBy],
];

export const DOCUMENTS_TEMPLATE_FIN_DOC = [
    DOCUMENTS_INPUTS[PrivateRoomInputsKeyName.docSeries],
    DOCUMENTS_INPUTS[PrivateRoomInputsKeyName.docNumber],
    DOCUMENTS_INPUTS[PrivateRoomInputsKeyName.docIssuedBy],
    DOCUMENTS_INPUTS[PrivateRoomInputsKeyName.docIssueDate],
    DOCUMENTS_INPUTS[PrivateRoomInputsKeyName.docExpiryDate],
];

export const DOCUMENTS_TEMPLATE_TEMP_RES = [
    DOCUMENTS_INPUTS[PrivateRoomInputsKeyName.docNumber],
    DOCUMENTS_INPUTS[PrivateRoomInputsKeyName.docIssueDate],
    DOCUMENTS_INPUTS[PrivateRoomInputsKeyName.docExpiryDate],
    DOCUMENTS_INPUTS[PrivateRoomInputsKeyName.docIssuedBy],
];

export const DOCUMENTS_TEMPLATE_RES_PERMIT = [
    DOCUMENTS_INPUTS[PrivateRoomInputsKeyName.docNumber],
    DOCUMENTS_INPUTS[PrivateRoomInputsKeyName.docIssueDate],
    DOCUMENTS_INPUTS[PrivateRoomInputsKeyName.docIssuedBy],
    DOCUMENTS_INPUTS[PrivateRoomInputsKeyName.docExpiryDate],
];

export const DOCUMENTS_PART_FORM: Record<Doc, DocumentTemplateType[]> = {
    [Doc.Empty]: [],
    [Doc.RfPassport]: DOCUMENTS_TEMPLATE_RF_PASPORT,
    [Doc.FidDoc]: DOCUMENTS_TEMPLATE_FIN_DOC,
    [Doc.TempRes]: DOCUMENTS_TEMPLATE_TEMP_RES,
    [Doc.ResPermit]: DOCUMENTS_TEMPLATE_RES_PERMIT,
};
