import { RuleObject } from 'antd/es/form';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StoreValue } from 'rc-field-form/lib/interface';

import { customValidator } from '~/utils/custom-validator';
import { isArrayWithItems } from '~/utils/is-array-with-items';

export const requiredUploadItemValidator =
    (isRequired: boolean, minCount?: number) => (_: RuleObject, currentValue: StoreValue) => {
        const notEnoughFiles = minCount && currentValue.length !== minCount;

        return customValidator(
            isRequired &&
                (!currentValue ||
                    !isArrayWithItems(currentValue) ||
                    (!!minCount && !!notEnoughFiles)),
        );
    };
