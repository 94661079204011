import { Rule } from 'antd/es/form';

import { PRIVATE_ROOM } from '~/constants/private-room';

export const generateRules = (key: string, docType: number) => {
    const arrRules = PRIVATE_ROOM.inputs.identityDocuments.find(({ keyName }) => keyName === key)
        ?.rules;

    const currentRules: Rule[] = [];

    if (typeof arrRules === 'object') {
        Object.entries(arrRules).forEach(([keyArr, value]) => {
            if (Number(keyArr) === docType) {
                value.forEach((item: Rule) => {
                    currentRules.push(item);
                });
            }
        });
    }

    return currentRules;
};
