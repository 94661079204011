import { AuditOutlined, HighlightOutlined, SearchOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/es/menu/hooks/useItems';

import { KEYS_FOR_TAB } from '~/components/check-and-sign-certificate/types/keys-for-tab';

export const OPTIONS_FOR_TAB = {
    checkCertificate: 'Проверка сертификата подписи',
    checkDocument: 'Проверка подписанного документа',
    signDocument: 'Подписание документа',
};

export const items: ItemType[] = [
    {
        key: KEYS_FOR_TAB.checkCertificate,
        icon: <SearchOutlined />,
        label: OPTIONS_FOR_TAB.checkCertificate,
    },
    {
        key: KEYS_FOR_TAB.checkDocument,
        icon: <AuditOutlined />,
        label: OPTIONS_FOR_TAB.checkDocument,
    },
    {
        key: KEYS_FOR_TAB.signDocument,
        icon: <HighlightOutlined />,
        label: OPTIONS_FOR_TAB.signDocument,
    },
];
