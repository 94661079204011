import { ModalTextVariant } from '~/constants/modal';
import { UserInfoResponse } from '~/rtk-queries/types/private-room';
import { CommonResponse } from '~/types/common';

const draftWord = 'draft';

export function getModalAnswerText(
    createData?: CommonResponse | CommonResponse<UserInfoResponse>,
    selectedTemplateName?: string,
): string | null {
    if (createData?.hasError) {
        return ModalTextVariant.errorTemplateMessage;
    }

    const isDraft = createData?.message?.toLocaleLowerCase().includes(draftWord);
    const modalText = isDraft
        ? `Черновик сертификата ${selectedTemplateName} от ${new Date().toLocaleString()} создан`
        : `Запрос на новый сертификат ${selectedTemplateName} от\u00A0${new Date().toLocaleString()} зарегистрирован`;

    return modalText;
}

export function getModalTitleText(
    createData?: CommonResponse | CommonResponse<UserInfoResponse>,
): string | null {
    if (createData?.hasError) {
        return ModalTextVariant.attention;
    }

    const isDraft = createData?.message?.toLocaleLowerCase().includes(draftWord);

    return isDraft ? ModalTextVariant.createDraft : ModalTextVariant.createRequest;
}
